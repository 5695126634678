import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { when } from 'src/app/modules/when';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { PCITemplateService } from 'src/services/api/pci-template.service';

@Component({
  selector: 'app-pci-template-detail',
  templateUrl: './pci-template-detail.component.html',
  styleUrls: ['./pci-template-detail.component.scss'],
})
export class PciTemplateDetailComponent implements OnInit {
  loading = true;
  templateId = '';
  name = '';
  questions: IQAT[] = [];
  pharmacyId = '';

  constructor(
    private store: Store,
    private pciTemplateService: PCITemplateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.templateId = this.route.snapshot.paramMap.get('templateId') ?? '';
    try {
      this.pharmacyId = await getLoginSession(this.store)
        .pipe(
          first(),
          map(s => s.pharmacy?.id ?? ''),
        )
        .toPromise();
      if (this.templateId === 'new-template') {
        [this.templateId, this.name, this.questions] = ['', '', []];
        this.loading = false;
      } else {
        await this.fetchPciTemplate();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchPciTemplate(): Promise<void> {
    [this.templateId, this.name, this.questions] = await this.pciTemplateService
      .find(this.templateId)
      .then(template => {
        if (template) {
          return [
            template.id,
            template.name ?? '',
            template.qa?.map(q => ({
              required: q.required,
              type: q.type,
              question: q.question,
              answers: when(q.answers)
                .on<string[]>(
                  a => !a || a.length === 0,
                  _ => (q.type === QuestionType.textbox ? [] : ['']),
                )
                .otherwise(a => a),
            })) ?? [],
          ];
        } else {
          return ['', '', []];
        }
      });
  }

  async saveTemplate() {
    if (!confirm('この内容で保存しますか？')) {
      return;
    }
    this.loading = true;
    try {
      const processedQuestions = this.questions.map(q => ({
        required: q.required,
        type: q.type,
        question: q.question,
        answers: q.type !== QuestionType.textbox ? q.answers.filter(a => a.trim() !== '') : [],
      }));
      if (this.templateId) {
        await this.pciTemplateService.update({
          id: this.templateId,
          pharmacy_id: this.pharmacyId,
          name: this.name,
          qa: processedQuestions,
        });
        this.router.navigate(['/pharmacy/pci-templates']);
      } else {
        await this.pciTemplateService.create({
          id: '',
          pharmacy_id: this.pharmacyId,
          name: this.name,
          qa: processedQuestions,
        });
        this.router.navigate(['/pharmacy/pci-templates']);
      }
    } catch (error) {
      console.log(error);
      alert('保存できませんでした。');
    } finally {
      this.loading = false;
    }
  }
}
