export const environment = {
  production: false,
  domain: 'localhost:4200',
  region: 'ap-northeast-1',
  api_base_url: 'https://demo.online-pci.net/v1/',
  chime_url: 'https://demo.online-pci.net/v1/chime/',
  userPoolIdForPharmacy: 'ap-northeast-1_SEHFBZIRx',
  clientIdForPharmacy: '63kane515mgr1a909ih0mmjmhd',
  userPoolIdForPharmacist: 'ap-northeast-1_y7AObRzk1',
  clientIdForPharmacist: '6261cu4ot8raaavu24eh8m25l',
  title: 'オンライン服薬指導システム（demo環境）',
};
