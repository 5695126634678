import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarOptions, EventClickArg, EventHoveringArg, EventMountArg, ViewMountArg } from '@fullcalendar/core';
import { format } from 'date-fns';
import { InstructionStatus, TimeFrame, TimeFrameForDisplay } from 'src/models/pci';
import { CalendarEntryColorPipe } from 'src/pipes/calendar_entry_color.pipe';
import { PciService } from 'src/services/api/pci.service';
import { CognitoService } from 'src/services/cognito.service';
import { TooltipService } from 'src/app/parts/hover-tooltip/hover-tooltip.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  private nameFilter = '';
  @Input('nameFilter') set _nameFilter(value: string) {
    this.nameFilter = value;
    this.filterByName();
  }
  @ViewChild('calendar') calendar!: FullCalendarComponent;
  @ViewChild('expansionPanel') expansionPanel!: MatExpansionPanel;
  reservations: (TimeFrameForDisplay | undefined)[] = [];
  calendarOptions: CalendarOptions = {
    editable: false,
    locale: 'ja',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    buttonText: {
      today: '今日',
      month: '月',
      week: '週',
      day: '日',
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
    },
    initialView: 'dayGridMonth',
    expandRows: true,
    weekends: true,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: false,
    displayEventTime: true,
    contentHeight: 'auto',
    eventClick: this.handleEventClick.bind(this),
    eventDidMount: this.handleEventMount.bind(this),
    eventMouseEnter: this.handleMouseEnter.bind(this),
    eventMouseLeave: this.handleMouseLeave.bind(this),
    viewWillUnmount: this.handleViewUnmount.bind(this),
    viewDidMount: this.handleViewMount.bind(this),
  };

  loading = true;
  private eventMountArgs: EventMountArg[] = [];
  private overlayRef?: OverlayRef;
  statusFilter = [true, false, true, false, false, false, true, true, false, false, false];
  filterControlTimeout?: NodeJS.Timeout;
  readonly instructionStatusNames = [
    InstructionStatus.requested,
    InstructionStatus.withdrawn,
    InstructionStatus.confirmed,
    InstructionStatus.cancelled_by_patient,
    InstructionStatus.cancelled_by_pharmacist,
    InstructionStatus.declined,
    InstructionStatus.meeting_performed,
    InstructionStatus.meeting_completed,
    InstructionStatus.completed,
    InstructionStatus.incomplete,
    InstructionStatus.cancelled,
  ];

  private get statusFilterString() {
    return this.statusFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.instructionStatusNames[index] + ',' : acc), '')
      .slice(0, -1);
  }

  constructor(
    private pciService: PciService,
    private calendarEntryColorPipe: CalendarEntryColorPipe,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private tooltipService: TooltipService,
  ) {}

  async ngOnInit() {
    try {
      this.loading = true;
      this.reservations = await this.fetchReservations();
      this.renderCalendarEvents();
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.overlayRef?.dispose();
  }

  private async fetchReservations() {
    return this.pciService
      .findAll({ status: this.statusFilterString })
      .then(result =>
        result.flatMap(r =>
          r.time_frames
            ? r.time_frames?.reduce(
                (acc: TimeFrameForDisplay[], cur, index) =>
                  cur.status !== 'declined' ? [...acc, new TimeFrameForDisplay(r, index)] : acc,
                [],
              )
            : [],
        ),
      );
  }

  private renderCalendarEvents() {
    const calendarEvents = this.reservations.map(reservation => ({
      start: reservation?.startDateTime,
      end: reservation?.endDateTime,
      title: reservation?.patientName,
      backgroundColor: this.calendarEntryColorPipe.transform(reservation?.status),
      id: reservation?.id,
      instructionStatus: reservation?.status,
      timeFrameIndex: reservation?.timeFrameIndex,
      timeFrames: reservation?.original.time_frames ?? [],
      patientName: reservation?.patientName ?? '',
      patientNameKana: reservation?.patientNameKana ?? '',
    }));
    this.calendar.getApi().removeAllEventSources();
    this.calendar.getApi().destroy();
    this.calendar.getApi().addEventSource(calendarEvents);
    this.calendar.getApi().render();
  }

  private filterByName() {
    const filters = this.nameFilter.trim().split(' ');
    this.eventMountArgs.forEach(a => {
      if (
        (filters.length === 1 && !filters[0]) ||
        filters.every(f => (a.event.extendedProps.patientName as string).includes(f)) ||
        filters.every(f => (a.event.extendedProps.patientNameKana as string).includes(f))
      ) {
        a.el.style.display = '';
      } else {
        a.el.style.display = 'none';
      }
    });
  }

  async filterByStatus(statusIndex: number) {
    if (this.filterControlTimeout !== null && this.filterControlTimeout !== undefined) {
      clearTimeout(this.filterControlTimeout);
    }
    this.statusFilter[statusIndex] = !this.statusFilter[statusIndex];
    this.filterControlTimeout = setTimeout(async () => {
      try {
        this.expansionPanel.close();
        this.loading = true;
        this.reservations = await this.fetchReservations();
        this.renderCalendarEvents();
      } finally {
        this.loading = false;
      }
    }, 1500);
  }

  private handleEventClick(clickInfo: EventClickArg) {
    this.overlayRef?.dispose();
    if (clickInfo.event.extendedProps.instructionStatus === InstructionStatus.requested) {
      const dialogRef = this.dialog.open(CalendarDetailDialog, {
        data: { clickInfo },
        minWidth: 400,
      });
      dialogRef.afterClosed().subscribe(async result => {
        console.log(`Dialog result: ${result}`);
        if (result.shouldReloadPcis) {
          this.reservations = await this.fetchReservations();
          this.renderCalendarEvents();
        }
      });
    } else {
      this.router.navigate([`/pharmacist/reservation/${clickInfo.event.id}`], { relativeTo: this.route });
    }
  }

  private handleViewUnmount(_: ViewMountArg) {
    this.eventMountArgs = [];
  }

  private handleViewMount(_: ViewMountArg) {
    this.eventMountArgs = [];
  }

  private handleEventMount(arg: EventMountArg) {
    const filters = this.nameFilter.trim().split(' ');
    arg.el.style.cursor = 'pointer';
    setTimeout(() => {
      if (
        (filters.length === 1 && !filters[0]) ||
        filters.every(f => (arg.event.extendedProps.patientName as string).includes(f)) ||
        filters.every(f => (arg.event.extendedProps.patientNameKana as string).includes(f))
      ) {
        arg.el.style.display = '';
      } else {
        arg.el.style.display = 'none';
      }
    });

    this.eventMountArgs.push(arg);
  }

  private handleMouseEnter(arg: EventHoveringArg) {
    const data = this.eventMountArgs
      .reduce((acc: EventMountArg[], curr) => {
        if (
          !curr.event._instance ||
          acc.map(a => a.event._instance?.defId).includes(curr.event._instance.defId) ||
          curr.event.id !== arg.event.id
        ) {
          return acc;
        }
        return [...acc, curr];
      }, [])
      .map(a => {
        a.el.style.backgroundColor = arg.view.type === 'dayGridMonth' ? '#fadbe1' : '#ff0000';
        return {
          start: a.event.start,
          end: a.event.end,
          title: a.event.title,
          backgroundColor: a.event.backgroundColor,
          id: a.event.id,
          selected: a.event._instance?.defId === arg.event._instance?.defId,
          instructionStatus: a.event.extendedProps.instructionStatus as InstructionStatus,
        };
      });
    const eventPosition = arg.el.getBoundingClientRect();
    const adjustX = (d => {
      if (d === 0) {
        return 0;
      }
      if (d === 6) {
        return -100;
      }
      return -50;
    })(arg.event.start?.getDay() ?? 2);
    const adjustY = data.length * -30 - 100;
    this.overlayRef = this.tooltipService.createTooltip(eventPosition.x + adjustX, eventPosition.y + adjustY, data);
  }

  private handleMouseLeave(arg: EventHoveringArg) {
    this.eventMountArgs
      .filter(a => a.event.id === arg.event.id)
      .forEach(a => (a.el.style.backgroundColor = a.view.type === 'dayGridMonth' ? '' : a.backgroundColor));
    this.overlayRef?.dispose();
  }

  ceil(x: number) {
    return Math.ceil(x);
  }
}

@Component({
  selector: 'calendar-detail-dialog',
  templateUrl: './calendar-detail-dialog.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarDetailDialog {
  reservation: EventClickArg;
  patientName = '';
  timeFrames: TimeFrame[] = [];
  selectedTimeFrame = 0;
  confirmButtonLoading = false;
  declineButtonLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CalendarDetailDialog>,
    @Inject(MAT_DIALOG_DATA) data: { clickInfo: EventClickArg },
    public pciService: PciService,
    public cognito: CognitoService,
  ) {
    this.reservation = data.clickInfo;
    this.timeFrames = (this.reservation.event.extendedProps.timeFrames as TimeFrame[]) ?? [];
    this.selectedTimeFrame = this.reservation.event.extendedProps.timeFrameIndex as number;
    this.patientName = this.reservation.event.extendedProps.patientName as string;
  }

  async confirm() {
    const selectDate = format(new Date(this.timeFrames[this.selectedTimeFrame].start_time), 'yyyy年MM月dd日 HH:mm');
    const message = `${selectDate}からの予約を確定します。よろしいですか？`;
    if (confirm(message)) {
      try {
        this.confirmButtonLoading = true;
        await this.pciService.confirm(this.reservation.event.id, this.selectedTimeFrame);
        this.dialogRef.close({ shouldReloadPcis: true });
      } finally {
        this.confirmButtonLoading = false;
      }
    }
  }

  async decline() {
    const message = `全ての予約をお断りします。よろしいですか？`;
    if (confirm(message)) {
      try {
        this.declineButtonLoading = true;
        await this.pciService.decline(this.reservation.event.id);
        this.dialogRef.close({ shouldReloadPcis: true });
      } finally {
        this.declineButtonLoading = false;
      }
    }
  }
}
