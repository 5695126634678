import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { PharmacistsComponent } from './pharmacy/pharmacists/pharmacists.component';
import { IndexComponent as PharmacyIndexComponent } from './pharmacy/index/index.component';
import { LoginComponent as PharmacyLoginComponent } from './pharmacy/login/login.component';
import { LoginComponent as PharmacistLoginComponent } from './pharmacist/login/login.component';
import { IndexComponent as PharmacistIndexComponent } from './pharmacist/index/index.component';
import { ReservationListComponent } from './pharmacist/reservation-list/reservation-list.component';
import { ReservationDetailComponent } from './pharmacist/reservation-detail/reservation-detail.component';
import { ImplementationComponent } from './pharmacist/implementation/implementation.component';
import { PatientsListComponent } from './pharmacist/patients-list/patients-list.component';
import { PatientsDetailComponent } from './pharmacist/patients-detail/patients-detail.component';
import { PrescriptionsComponent } from './pharmacist/prescriptions/prescriptions.component';
import { PrescriptionDetailComponent } from './pharmacist/prescriptions/detail/detail.component';
import { AuthGuard } from './guard/auth.guard';
import { CbfTemplatesComponent } from './pharmacy/cbf-templates/cbf-templates.component';
import { CbfTemplateDetailComponent } from './pharmacy/cbf-templates/cbf-template-detail/cbf-template-detail.component';
import { PcfTemplatesComponent } from './pharmacy/pcf-templates/pcf-templates.component';
import { PcfTemplateDetailComponent } from './pharmacy/pcf-templates/pcf-template-detail/pcf-template-detail.component';
import { ReservationSlotsComponent } from './pharmacy/reservation-slots/reservation-slots.component';
import { PharmacyRegistrationComponent } from './pharmacy/pharmacy-registration/pharmacy-registration.component';
import { FollowListComponent } from './pharmacist/follow/follow-list.component';
import { FollowBaseSelectComponent } from './pharmacist/follow/follow-base-select/follow-base-select.component';
import { NewFollowComponent } from './pharmacist/follow/new-follow/new-follow.component';
import { PharmacyProfileComponent } from './pharmacy/pharmacy-profile/pharmacy-profile.component';
import { PciTemplateListComponent } from './pharmacist/pci-template-list/pci-template-list.component';
import { MedicalQuestionnaireTemplateComponent } from './pharmacy/medical-questionnaire-template/medical-questionnaire-template.component';
import { PciTemplatesComponent } from './pharmacy/pci-templates/pci-templates.component';
import { PciTemplateDetailComponent } from './pharmacy/pci-templates/pci-template-detail/pci-template-detail.component';
import { ReservationSlotDetailComponent } from './pharmacy/reservation-slots/detail/reservation-slot-detail.component';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { title: 'オンライン服薬指導システム' },
    canActivate: [LoginGuard],
  },
  {
    path: 'index',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'pharmacy',
    children: [
      {
        path: 'login',
        component: PharmacyLoginComponent,
        data: { title: '薬局管理者ログイン' },
        canActivate: [LoginGuard],
      },
      {
        path: 'registration',
        component: PharmacyRegistrationComponent,
        data: { title: '薬局登録' },
      },
      {
        path: 'profile',
        component: PharmacyProfileComponent,
        data: { title: '薬局情報編集', backPass: '/pharmacy/index' },
        canActivate: [AuthGuard],
      },
      {
        path: 'index',
        component: PharmacyIndexComponent,
        data: { title: '薬局管理者機能一覧' },
        canActivate: [AuthGuard],
      },
      {
        path: 'pharmacists',
        component: PharmacistsComponent,
        data: { title: '薬剤師一覧', backPass: '/pharmacy/index' },
        canActivate: [AuthGuard],
      },
      {
        path: 'cbf-templates',
        children: [
          {
            path: '',
            component: CbfTemplatesComponent,
            data: { title: 'チャットボット服薬フォローテンプレート一覧', backPass: '/pharmacy/index' },
            canActivate: [AuthGuard],
          },
          {
            path: ':templateId',
            component: CbfTemplateDetailComponent,
            data: { title: 'チャットボット服薬フォローテンプレート一覧', backPass: '/pharmacy/cbf-templates' },
          },
        ],
      },
      {
        path: 'pcf-templates',
        children: [
          {
            path: '',
            component: PcfTemplatesComponent,
            data: { title: '服薬フォローテンプレート一覧', backPass: 'pharmacy/index' },
            canActivate: [AuthGuard],
          },
          {
            path: ':templateId',
            component: PcfTemplateDetailComponent,
            data: { title: '服薬フォローテンプレート詳細', backPass: 'pharmacy/pcf-templates' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'medical-questionnaire-template',
        component: MedicalQuestionnaireTemplateComponent,
        data: { title: '問診票テンプレート編集', backPass: '/pharmacy/index' },
        canActivate: [AuthGuard],
      },
      {
        path: 'reservation-slots',
        children: [
          {
            path: '',
            component: ReservationSlotsComponent,
            data: { title: '予約枠一覧', backPass: '/pharmacy/index' },
            canActivate: [AuthGuard],
          },
          {
            path: 'detail',
            component: ReservationSlotDetailComponent,
            data: { title: '予約枠詳細', backPass: 'pharmacy/reservation-slots' },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'pci-templates',
        children: [
          {
            path: '',
            component: PciTemplatesComponent,
            data: { title: '服薬指導テンプレート一覧', backPass: 'pharmacy/index' },
            canActivate: [AuthGuard],
          },
          {
            path: ':templateId',
            component: PciTemplateDetailComponent,
            data: { title: '服薬指導テンプレート詳細', backPass: 'pharmacy/pci-templates' },
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'pharmacist',
    children: [
      {
        path: 'login',
        component: PharmacistLoginComponent,
        data: { title: '薬剤師ログイン' },
        canActivate: [LoginGuard],
      },
      {
        path: 'index',
        component: PharmacistIndexComponent,
        data: { title: '薬剤師機能一覧' },
        canActivate: [AuthGuard],
      },
      {
        path: 'reservation',
        children: [
          {
            path: '',
            component: ReservationListComponent,
            canActivate: [AuthGuard],
            data: { title: '服薬指導予約一覧', backPass: 'pharmacist/index' },
          },
          {
            path: ':pciId',
            children: [
              {
                path: '',
                component: ReservationDetailComponent,
                canActivate: [AuthGuard],
                data: { title: '服薬指導予約詳細', backPass: 'pharmacist/reservation' },
              },
              {
                path: 'implementation',
                component: ImplementationComponent,
                canActivate: [AuthGuard],
                data: { title: '服薬指導実施', backPass: 'pharmacist/reservation' },
              },
              {
                path: 'pci-templates',
                component: PciTemplateListComponent,
                canActivate: [AuthGuard],
                data: { title: '服薬指導テンプレート一覧', backPass: 'pharmacist/reservation' },
              },
            ],
          },
        ],
      },
      {
        path: 'patients',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: PatientsListComponent,
            canActivate: [AuthGuard],
            data: { title: '患者一覧', backPass: 'pharmacist/index' },
          },
          {
            path: ':patientInfoId',
            children: [
              {
                path: '',
                component: PatientsDetailComponent,
                canActivate: [AuthGuard],
                data: { title: '患者情報詳細', backPass: 'pharmacist/patients' },
              },
            ],
          },
        ],
      },
      {
        path: 'prescriptions',
        children: [
          {
            path: '',
            component: PrescriptionsComponent,
            canActivate: [AuthGuard],
            data: { title: '処方箋一覧', backPass: 'pharmacist/index' },
          },
          {
            path: ':prescriptionId',
            component: PrescriptionDetailComponent,
            canActivate: [AuthGuard],
            data: { title: '処方箋詳細', backPass: 'pharmacist/prescriptions' },
          },
        ],
      },
      {
        path: 'follow',
        children: [
          {
            path: '',
            component: FollowListComponent,
            canActivate: [AuthGuard],
            data: { title: '服薬フォロー一覧', backPass: 'pharmacist/index' },
          },
          {
            path: 'base-select',
            component: FollowBaseSelectComponent,
            canActivate: [AuthGuard],
            data: { title: '服薬フォローベース選択', backPass: 'pharmacist/follow' },
          },
          {
            path: 'new',
            component: NewFollowComponent,
            canActivate: [AuthGuard],
            data: { title: '新規服薬フォロー編集', backPass: 'pharmacist/follow/base-select' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
