import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { when } from 'src/app/modules/when';
import { InstructionStatus, Pci } from 'src/models/pci';
import { PatientInfoService } from './api/patient-info.service';
import { PciService } from './api/pci.service';
import { PrescriptionService } from './api/prescription.service';

export type NewArrival = { type: string; date?: number; patientName: string; title: string; routerLink?: string };

@Injectable({ providedIn: 'root' })
export class NewArrivalService {
  constructor(
    private readonly store: Store,
    private readonly prescriptionService: PrescriptionService,
    private readonly pciService: PciService,
    private readonly patientInfoService: PatientInfoService,
  ) {}

  async getNewArrivals(): Promise<NewArrival[]> {
    const pharmacyId = await getLoginSession(this.store)
      .pipe(
        first(),
        map(s => s.pharmacist?.pharmacy?.id ?? ''),
      )
      .toPromise();
    const [pcis, prescriptions] = await Promise.all([
      this.pciService.findAll().then(result => result.map(r => new Pci(r))),
      this.prescriptionService.findAll(pharmacyId),
    ]);
    const filteredPcis = pcis
      .filter(r => r.status === InstructionStatus.requested || r.timeFrameOfTodayAndAfterCurrentTime)
      .map(p => {
        const date = p.updated_at;
        const patientName = `${p.patient_info?.family_name ?? '-'} ${p.patient_info?.given_name ?? '-'}`;
        const [title, routerLink] = when(p)
          .on(
            pci => pci.status === InstructionStatus.requested,
            () => ['確認が必要な服薬指導予約があります。', '/pharmacist/reservation'],
          )
          .on(
            pci => !!pci.timeFrameOfTodayAndAfterCurrentTime,
            () => {
              // onの第一引数でundefinedでないことを確認済み
              // tslint:disable-next-line
              const startTime = new Date(p.timeFrameOfTodayAndAfterCurrentTime!.start_time);
              const startHour = startTime.getHours();
              const startMinutes = startTime.getMinutes();

              return [
                `本日${startHour}時${startMinutes}分から服薬指導の予約があります。`,
                '/pharmacist/reservation/' + p.id,
              ];
            },
          )
          .otherwise(() => {
            throw new Error('invalid data.');
          });
        return { type: 'pci', date, patientName, title, routerLink };
      });
    const filteredPrescription = prescriptions
      .filter(r => r.status === 'sent' || r.status === 'confirmed' || r.status === 'dispensed')
      .map(p => {
        const date = p.created_at;
        const patientName = `${p?.patient_info?.family_name ?? '-'} ${p?.patient_info?.given_name ?? '-'}`;
        const title = when(p.status)
          .on(
            s => s === 'sent',
            _ => '確認が必要な処方箋があります。',
          )
          .on(
            s => s === 'confirmed',
            _ => '調剤待ちの処方箋があります',
          )
          .on(
            s => s === 'dispensed',
            _ => '完了待ちの処方箋があります',
          )
          .otherwise(_ => '');
        const routerLink = '/pharmacist/prescriptions/' + p.id;
        return { type: 'prescription', date, patientName, title, routerLink };
      });
    return [...filteredPcis, ...filteredPrescription].sort((a, b) => {
      if (a.date === undefined) {
        return 1;
      } else if (b.date === undefined) {
        return -1;
      } else {
        return b.date - a.date;
      }
    });
  }
}
