<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading">
  <table mat-table [dataSource]="pci ? [pci] : []">
    <ng-container matColumnDef="timeText">
      <th mat-header-cell *matHeaderCellDef>開始日時</th>
      <td mat-cell *matCellDef="let element">{{ element.reservation }}</td>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>患者名</th>
      <td mat-cell *matCellDef="let element">
        <a class="patient-info-link-global" [routerLink]="'/pharmacist/patients/' + element.patientInfoId">
          {{ element.patientName }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="pharmacistName">
      <th mat-header-cell *matHeaderCellDef>薬剤師名</th>
      <td mat-cell *matCellDef="let element">{{ element.pharmacistName }}</td>
    </ng-container>

    <ng-container matColumnDef="wasConfirmed">
      <th mat-header-cell *matHeaderCellDef>予約確認</th>
      <td mat-cell *matCellDef="let element">{{ element.status | reservation_status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="template-area" *ngIf="!!this.pci.pci_questions_template_id">
    <qa-template (modelEvent)="qaAnswer = $event"></qa-template>
  </div>

  <div class="buttons-area">
    <button
      mat-raised-button
      *ngIf="this.pci?.status === 'confirmed' && !this.pci.is_meeting_started"
      color="warn"
      (click)="cancel()"
    >
      予約キャンセル
    </button>
    <button
      mat-raised-button
      *ngIf="this.pci?.status === 'confirmed' && !this.pci.pci_answers"
      color="primary"
      fxFlexOffset="10px"
      (click)="specifyPciTemplate()"
    >
      服薬指導テンプレートを指定
    </button>
    <button *ngIf="isEndMeeting" mat-raised-button color="warn" fxFlexOffset="10px" (click)="complete()">
      服薬指導を終了
    </button>
    <button
      *ngIf="this.pci.is_meeting_started"
      mat-raised-button
      color="warn"
      fxFlexOffset="10px"
      (click)="forceEndMeeting()"
    >
      服薬指導を強制終了
    </button>
    <button
      mat-raised-button
      *ngIf="isEndMeeting"
      (click)="savePciQa()"
      aria-label="incomplete meeting"
      color="primary"
      fxFlexOffset="10px"
    >
      服薬指導QAを保存する
    </button>
    <button *ngIf="isStartMeeting" mat-raised-button color="primary" fxFlexOffset="auto" (click)="implementation()">
      服薬指導を開始
    </button>
  </div>
</div>
