<div class="root" fxLayout="column">
  <div fxLayout="row" class="patient-search-form-container">
    <mat-form-field fxFlex="50">
      <mat-label>名前で検索</mat-label>
      <input matInput type="text" [formControl]="patientFilterController" />
    </mat-form-field>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <mat-paginator
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
      ></mat-paginator>
      <div class="table">
        <mat-card class="header" fxLayout="row">
          <div fxFlex fxLayout="row" fxLayoutGap="5px" class="header-content">
            <div fxFlexAlign="center">受信日時</div>
          </div>
          <div fxFlex class="header-content" fxFlexAlign="center">患者氏名</div>
          <div
            fxFlex
            fxLayout="row"
            class="header-content clickable"
            #stateFilterControllerOrigin="cdkOverlayOrigin"
            cdk-overlay-origin
            (click)="filterByStatus()"
          >
            <div fxFlexAlign="center">実施状況</div>
            <mat-icon fxFlexAlign="center">filter_alt</mat-icon>
          </div>
          <div fxFlex fxLayout="row" fxLayoutGap="5px" class="header-content">
            <div fxFlexAlign="center">進捗</div>
          </div>
        </mat-card>
        <mat-card
          *ngFor="let element of prescriptions; index as i"
          class="element clickable"
          [class.unclickable]="prescriptionsDisabled[i]"
          fxLayout="row"
          [routerLink]="'./' + element.id"
        >
          <div fxFlex class="element-content">{{ element.created_at | date: 'yyyy/MM/dd HH:mm:ss' }}</div>
          <div fxFlex class="element-content">
            <a (click)="$event.stopPropagation()" [routerLink]="'/pharmacist/patients/' + element.patient_info_id">{{
              getPatientName(element.patient_info)
            }}</a>
          </div>
          <div fxFlex class="element-content">{{ element.status | prescription_status }}</div>
          <div fxFlex class="element-content" fxLayout="column" fxLayoutAlign="center">
            <div class="progress-bar-wrap" fxLayout="column" fxLayoutAlign="center">
              <mat-progress-bar
                mode="determinate"
                [value]="element.status | prescription_progress"
                [color]="element.status === 'req_resend' || element.status === 'rejected' ? 'warn' : 'primary'"
              ></mat-progress-bar>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
