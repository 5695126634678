import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { first, map } from 'rxjs/operators';
import { loginSessions } from 'src/app/app-store/actions/session.actions';
import { environment } from 'src/environments/environment';
import { IPharmacy } from 'src/models';
import { CognitoService, configure } from 'src/services/cognito.service';
import { PasswordPromptService } from 'src/app/parts/password-prompt/password-prompt.component';
import { filteredPrefectures, prefectures } from 'src/models/prefectures';
import { Observable, of } from 'rxjs';
import { ZipToAddressService } from 'src/services/zip-to-address.service';

@Component({
  selector: 'app-pharmacy-registration',
  templateUrl: './pharmacy-registration.component.html',
  styleUrls: ['./pharmacy-registration.component.scss'],
})
export class PharmacyRegistrationComponent implements OnInit {
  loading = true;
  fetchingAddress = false;
  readonly pharmacyCodeFormControl = new FormControl('', [Validators.required]);
  readonly companyNameFormControl = new FormControl('', [Validators.required]);
  readonly storeNameFormControl = new FormControl('', [Validators.required]);
  readonly zipFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{7}$')]);
  readonly prefectureFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(' + prefectures.reduce((acc: string, cur) => acc + cur + '|', '').slice(0, -1) + ')$'),
  ]);
  readonly address1FormControl = new FormControl('', [Validators.required]);
  readonly address2FormControl = new FormControl('', [Validators.required]);
  readonly address3FormControl = new FormControl('');
  readonly emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  readonly telFormControl = new FormControl('', [Validators.required, Validators.pattern('^0[0-9]{9,10}$')]);
  readonly latitudeFormControl = new FormControl('', [Validators.pattern('^[+-]?[0-9]+.[0-9]+([eE][+-]?[0-9]+)?$')]);
  readonly longitudeFormControl = new FormControl('', [Validators.pattern('^[+-]?[0-9]+.[0-9]+([eE][+-]?[0-9]+)?$')]);
  web = '';
  private cognitoId = '';
  private id = '';
  filteredPrefectures: Observable<string[]> = of([]);

  get areAllFormsValid() {
    return [
      this.pharmacyCodeFormControl,
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.emailFormControl,
      this.telFormControl,
      this.longitudeFormControl,
      this.latitudeFormControl,
    ].every(c => c.valid);
  }

  constructor(
    private readonly store: Store,
    private readonly cognito: CognitoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly passwordPromptService: PasswordPromptService,
    private readonly zipToAddressService: ZipToAddressService,
  ) {
    this.filteredPrefectures = this.prefectureFormControl.valueChanges.pipe(map((v: string) => filteredPrefectures(v)));
  }

  async ngOnInit() {
    this.loading = true;
    [this.cognitoId, this.id] = await this.route.queryParams
      .pipe(first())
      .toPromise()
      .then(result => [result.name ?? '', result.id ?? ''])
      .catch(_ => ['', '']);
    if (!this.cognitoId || !this.id) {
      this.router.navigate(['index']);
    }
    this.loading = false;
  }

  async getAddress() {
    this.fetchingAddress = true;
    configure('pharmacy');
    await this.zipToAddressService
      .getAddress(this.zipFormControl.value as string)
      .then(result => {
        if (result.notFound) {
          alert('該当する住所が見つかりませんでした。');
          return;
        }
        this.prefectureFormControl.setValue(result.prefecture);
        this.address1FormControl.setValue(result.address);
      })
      .catch(error => {
        console.log(error);
        return;
      });
    this.fetchingAddress = false;
  }

  async register() {
    this.loading = true;
    configure('pharmacy');
    const password = (await this.passwordPromptService.prompt()) ?? '';
    try {
      await Auth.signIn(this.cognitoId, password);
    } catch {
      this.loading = false;
      alert('パスワードが違います。');
      return;
    }
    const token = await this.cognito.getAccessToken();
    const config = {
      headers: {
        Authorization: token.getJwtToken(),
        'Content-Type': 'application/json',
      },
    };
    const baseUrl = environment.api_base_url;
    try {
      const data: Omit<IPharmacy, 'is_active' | 'activated_at'> = {
        id: this.id,
        pharmacy_code: this.pharmacyCodeFormControl.value,
        company_name: this.companyNameFormControl.value,
        store_name: this.storeNameFormControl.value,
        zip: this.zipFormControl.value,
        prefecture: this.prefectureFormControl.value,
        address1: this.address1FormControl.value,
        address2: this.address2FormControl.value,
        address3: this.address3FormControl.value,
        tel: this.telFormControl.value,
        email: this.emailFormControl.value,
        location: {
          latitude: Number(this.latitudeFormControl.value),
          longitude: Number(this.longitudeFormControl.value),
        },
        web: this.web,
      };
      await axios.post<IPharmacy>(`${baseUrl}pharmacy/pharmacies`, data, config);
    } catch (error) {
      alert('情報の登録に失敗しました。');
      console.log(error);
    }
    this.store.dispatch(loginSessions({ email: this.cognitoId, password, type: 'pharmacy' }));
    this.loading = false;
  }
}
