import { Injectable } from '@angular/core';
import { IReservationSlot } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ReservationSlotService extends ServiceBase<IReservationSlot> {
  readonly propertyName = 'reservations';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'reservation_slots');
  }

  public findAll(params?: { limit?: number }): Promise<IReservationSlot[]> {
    return super.findAll(params);
  }

  public findAllWithPagination(params?: { limit?: number; last_key?: string }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IReservationSlot[],
      pagination: result.pagination,
    }));
  }
}
