import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPatientInfo, IPharmacist } from 'src/models';
import { Pci } from 'src/models/pci';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PciService } from 'src/services/api/pci.service';

@Component({
  selector: 'app-patients-detail',
  templateUrl: './patients-detail.component.html',
  styleUrls: ['./patients-detail.component.scss'],
})
export class PatientsDetailComponent implements OnInit {
  patientInfo: IPatientInfo | null = null;
  reservations: Pci[] = [];
  pharmacists: IPharmacist[] = [];
  loading = true;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private patientInfoService: PatientInfoService,
    private pciService: PciService,
  ) {}

  async ngOnInit() {
    try {
      this.loading = true;
      this.patientInfo = await this.patientInfoService.find(this.route.snapshot.paramMap.get('patientInfoId') ?? '');
      this.reservations = (await this.pciService.findAll())
        .map(pci => new Pci(pci))
        .filter(pci => pci.patient_info_id === this.patientInfo?.id)
        .filter(pci => 0 < pci.time_frames.length)
        .filter(pci => pci.startTimeOfFirstTimeFrame !== undefined)
        // filterでpci.startTimeOfFirstTimeFrameがundefinedにならないことを確認済み
        // tslint:disable-next-line
        .sort((a, b) => a.startTimeOfFirstTimeFrame! - b.startTimeOfFirstTimeFrame!);
    } finally {
      this.loading = false;
    }
  }
}
