<div class="out">
  <div class="root" fxLayout="column" fxLayoutGap="15px">
    <div>{{ message }}</div>
    <div class="input-wrap" fxFlexAlign="stretch" fxLayout="column">
      <input class="input" type="password" [(ngModel)]="password" (keyup.enter)="submit()" #input />
    </div>
    <div fxLayout="row-reverse" fxLayoutGap="15px">
      <button class="button cancel-button" (click)="cancel()">キャンセル</button>
      <button class="button ok-button" (click)="submit()">OK</button>
    </div>
  </div>
</div>
