<div class="list-root">
  <div class="list-container">
    <mat-paginator
      [length]="totalRecords"
      [pageIndex]="pageNumber"
      [pageSize]="pageSize"
      (page)="pageEvent($event)"
    ></mat-paginator>
    <table mat-table [dataSource]="reservations">
      <ng-container matColumnDef="receptionHours">
        <th mat-header-cell *matHeaderCellDef>受付日時</th>
        <td mat-cell *matCellDef="let element">
          {{ element.receptionHours | date: 'yyyy/MM/dd HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="patientName">
        <th mat-header-cell *matHeaderCellDef>患者名</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="$event.stopPropagation()" [routerLink]="'/pharmacist/patients/' + element.patientInfoId">{{
            element.patientName
          }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="pharmacistName">
        <th mat-header-cell *matHeaderCellDef>薬剤師名</th>
        <td mat-cell *matCellDef="let element">{{ element.pharmacistName }}</td>
      </ng-container>

      <ng-container matColumnDef="patientTel">
        <th mat-header-cell *matHeaderCellDef>電話番号</th>
        <td mat-cell *matCellDef="let element">{{ element.patientTel }}</td>
      </ng-container>

      <ng-container matColumnDef="reservation">
        <th mat-header-cell *matHeaderCellDef>予約日時</th>
        <td mat-cell *matCellDef="let element">{{ element.reservation }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th
          #statusHeader="cdkOverlayOrigin"
          cdk-overlay-origin
          mat-header-cell
          *matHeaderCellDef
          [style.cursor]="'pointer'"
          (click)="filterByStatus()"
        >
          <span class="status">実施状況</span>
          <mat-icon>filter_alt</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.status | reservation_status }}
          <mat-chip-list>
            <mat-chip *ngIf="element.is_meeting_started" class="chips">通話中</mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        class="clickable"
        [class.unclickable]="reservationsDisabled[i]"
        *matRowDef="let row; columns: displayedColumns; index as i"
        (click)="openDetail(row)"
      ></tr>
    </table>
  </div>
  <div class="loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
