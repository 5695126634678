import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { IPCF } from 'src/models/pcf';
import { IPCFTemplate } from 'src/models/pcf-template';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { PcfService } from 'src/services/api/pcf.service';

@Component({
  selector: 'app-follow-base-select',
  templateUrl: './follow-base-select.component.html',
  styleUrls: ['./follow-base-select.component.scss'],
})
export class FollowBaseSelectComponent implements OnInit {
  private pharmacy_id = '';
  follows: PCFForDisplay[] = [];
  pcfTemplates: IPCFTemplate[] = [];
  loading = true;
  constructor(
    private store: Store,
    private pcfTemplateService: PCFTemplateService,
    private pcfService: PcfService,
    private patientInfoService: PatientInfoService,
  ) {}

  ngOnInit(): void {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacist),
        filter(isNotNull),
        take(1),
      )
      .subscribe(pharmacist => {
        this.pharmacy_id = pharmacist.pharmacy?.id ?? '';
      });
    Promise.all([
      Promise.all([
        this.pcfService.findAll(),
        this.patientInfoService.findAll().then(result => result.map(i => i.patient_info)),
      ]).then(result => {
        this.follows = result[0]
          .map(follow => {
            const info = result[1].find(i => i.id === follow.patient_info_id);
            return { ...follow, patient_name: (info?.family_name ?? '') + ' ' + (info?.given_name ?? '') };
          })
          .sort((a, b) => {
            if (a.send_at !== null && a.send_at !== undefined) {
              if (b.send_at !== null && b.send_at !== undefined) {
                return b.send_at - a.send_at;
              }
              return -1;
            }
            return 1;
          });
      }),
      this.pcfTemplateService.findAll(),
    ]).then(result => {
      this.pcfTemplates = result[1];
      this.loading = false;
    });
  }
}

interface PCFForDisplay extends IPCF {
  patient_name: string;
}
