import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  ViewChild,
} from '@angular/core';
import { first, map } from 'rxjs/operators';

export const PASSWORD_PROMPT_MESSAGE = new InjectionToken<{ message: string; overlayRef: OverlayRef }>(
  'PASSWORD_PROMPT_MESSAGE',
);

@Component({
  selector: 'app-password-prompt',
  templateUrl: './password-prompt.component.html',
  styleUrls: ['./password-prompt.component.scss'],
})
export class PasswordPromptComponent implements AfterViewInit {
  @ViewChild('input') input!: ElementRef;
  message = '';
  password: string | null = '';
  private overlayRef: OverlayRef;

  constructor(@Inject(PASSWORD_PROMPT_MESSAGE) data: { message: string; overlayRef: OverlayRef }) {
    this.message = data.message;
    this.overlayRef = data.overlayRef;
  }

  ngAfterViewInit() {
    this.input.nativeElement.focus();
  }

  submit() {
    this.overlayRef.dispose();
  }
  cancel() {
    this.password = null;
    this.overlayRef.dispose();
  }
}

@Injectable({
  providedIn: 'root',
})
export class PasswordPromptService {
  constructor(private readonly parentInjector: Injector, private readonly overlay: Overlay) {}
  prompt(message?: string) {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .top();
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const config = {
      positionStrategy,
      scrollStrategy,
      width: 'auto',
      height: 'auto',
      hasBackdrop: true,
      backdropClass: ['backdrop-class'],
    };
    const overlayRef = this.overlay.create(config);
    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [
        {
          provide: PASSWORD_PROMPT_MESSAGE,
          useValue: { message: message ?? 'パスワードを入力してください。', overlayRef },
        },
      ],
    });
    const componentRef = overlayRef.attach(new ComponentPortal(PasswordPromptComponent, null, injector));
    return overlayRef
      .detachments()
      .pipe(
        first(),
        map(() => componentRef.instance.password),
      )
      .toPromise();
  }
}
