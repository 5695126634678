<div class="root">
  <div fxLayout="column" fxLayoutGap="20px">
    <div class="top-area" fxLayout="row" fxLayoutAlign="space-between">
      <mat-form-field>
        <mat-label>テンプレート名</mat-label>
        <input class="input pci-template-name-input" matInput [(ngModel)]="name" />
      </mat-form-field>
      <div fxLayout="row-reverse">
        <loading-button
          [name]="'保存'"
          [disabled]="loading"
          [color]="'primary'"
          [loading]="loading"
          (click)="saveTemplate()"
        ></loading-button>
      </div>
    </div>
    <app-questionnaire-editor [(questions)]="questions" [disabled]="loading"></app-questionnaire-editor>
  </div>
</div>
