import { Component, Inject, InjectionToken, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSession from '../app-store/reducers';
import { logoutSessions } from 'src/app/app-store/actions/session.actions';
import { IPharmacist, IPharmacy } from 'src/models';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getLastChild } from '../modules/routeModule';
import { getLoginSession } from '../modules/storeModules';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { NewArrival, NewArrivalService } from 'src/services/new-arrival.service';
import { CdkOverlayOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { animate, style, transition, trigger } from '@angular/animations';

const NOTIFICATION_DATA = new InjectionToken<{ notices: NewArrival[]; overlayRef: OverlayRef }>('NOTIFICATION_DATA');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  notificationButtonDisabled = true;
  pharmacist$: Observable<IPharmacist | null> = of(null);
  pharmacy$: Observable<IPharmacy | null> = of(null);
  url$: Observable<string> = of('');
  parentPass$: Observable<string | null> = of(null);
  atPharmacistTop$: Observable<boolean> = of(false);
  showLogoutButton$ = of(false);
  linkToTop$: Observable<string> = of('/index');
  timer5min$: Observable<void> = of();
  noticesSubscription: Subscription;
  notices: NewArrival[] = [];
  previousNotices: NewArrival[] = [];
  notificationSkip = 2;
  @Input() title!: string;
  @ViewChild('overlayOrigin') overlayOrigin!: CdkOverlayOrigin;

  constructor(
    private readonly store: Store<fromSession.State>,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly newArrivalService: NewArrivalService,
    private readonly overlay: Overlay,
    private readonly _injector: Injector,
  ) {
    const session$ = getLoginSession(this.store);
    this.pharmacist$ = session$.pipe(map(s => s.pharmacist));
    this.pharmacy$ = session$.pipe(map(s => (s.pharmacy ? s.pharmacy : s.pharmacist?.pharmacy ?? null)));
    this.url$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => (e as NavigationEnd).url),
    );
    this.parentPass$ = this.url$.pipe(map(_ => getLastChild(this.activatedRoute.snapshot).data.backPass ?? null));
    this.atPharmacistTop$ = this.url$.pipe(map(u => u === '/pharmacist/index'));
    this.showLogoutButton$ = this.url$.pipe(map(u => u !== '/' && !u.includes('login')));
    this.linkToTop$ = session$.pipe(
      map(s => (s.pharmacist ? '/pharmacist/index' : s.pharmacy ? '/pharmacy/index' : '/index')),
    );
    this.timer5min$ = new Observable(subscriber => {
      subscriber.next();
      setInterval(() => subscriber.next(), 300000);
    });
    this.noticesSubscription = combineLatest([this.pharmacist$, this.timer5min$])
      .pipe(mergeMap(async ([p, _]) => (p ? await this.newArrivalService.getNewArrivals() : [])))
      .subscribe(n => {
        console.log('fetching latest information...');
        this.previousNotices = this.notices;
        this.notices = n;
        const difference = this.notices.reduce(
          (acc: { prescription: number; pci: number }, cur) => {
            if (
              !this.previousNotices.find(
                p => cur.patientName === p.patientName && cur.date === p.date && cur.type === p.type,
              )
            ) {
              return cur.type === 'prescription'
                ? { prescription: ++acc.prescription, pci: acc.pci }
                : { prescription: acc.prescription, pci: ++acc.pci };
            }
            return acc;
          },
          { prescription: 0, pci: 0 },
        );
        if (!document.hasFocus() && (difference.prescription || difference.pci)) {
          new Notification('Connect Online', {
            icon: '/assets/logo.png',
            body:
              (difference.pci ? `服薬指導に関する新しい通知が${difference.pci}件あります。\n` : '') +
              (difference.prescription ? `処方箋に関する新しい通知が${difference.prescription}件あります。` : ''),
          }).onclick = _ => {
            window.focus();
          };
        }
      });

    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }

  ngOnDestroy() {
    this.noticesSubscription.unsubscribe();
  }

  async logout() {
    this.store.dispatch(logoutSessions());
  }

  back() {
    const routerSnapshot = getLastChild(this.activatedRoute.snapshot);
    if (routerSnapshot.data.title !== '服薬指導実施') {
      this.router.navigate([`${routerSnapshot.data.backPass ?? null}`]);
      return;
    }
    if (confirm('服薬指導実施画面から離れてもよろしいですか？')) {
      this.router.navigate([`pharmacist/reservation/${routerSnapshot.params.pciId}`]);
    }
  }

  async openNotificationsOverlay() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.overlayOrigin.elementRef)
      .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]);
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const config = {
      positionStrategy,
      scrollStrategy,
      width: 'auto',
      height: 'auto',
      hasBackdrop: true,
      backdropClass: ['notification-overlay-backdrop'],
    };
    const overlayRef = this.overlay.create(config);
    const injector = Injector.create({
      parent: this._injector,
      providers: [
        {
          provide: NOTIFICATION_DATA,
          useValue: { data: this.notices, ref: overlayRef },
        },
      ],
    });
    overlayRef.attach(new ComponentPortal(HeaderNotificationsOverlayComponent, null, injector));
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());
  }
}

@Component({
  selector: 'header-notifications-overlay',
  templateUrl: 'header-notifications-overlay.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.2s 0s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class HeaderNotificationsOverlayComponent {
  data: NewArrival[] = [];
  ref: OverlayRef;
  constructor(
    @Inject(NOTIFICATION_DATA)
    public readonly param: {
      data: NewArrival[];
      ref: OverlayRef;
    },
  ) {
    this.data = param.data ?? [];
    this.ref = param.ref;
  }

  public sliceString(str: string, letters: number) {
    if (str.length < letters) {
      return str;
    }
    return str.slice(0, letters) + '...';
  }

  onclick() {
    this.ref.dispose();
  }
}
