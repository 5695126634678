import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { when } from 'src/app/modules/when';
import { IPharmacy } from 'src/models';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { MQTemplateService } from 'src/services/api/mq-template.service';
import { PharmacyService } from 'src/services/api/pharmacy.service';

@Component({
  selector: 'app-medical-questionnaire-template',
  templateUrl: './medical-questionnaire-template.component.html',
  styleUrls: ['./medical-questionnaire-template.component.scss'],
})
export class MedicalQuestionnaireTemplateComponent implements OnInit {
  pharmacy: IPharmacy | null = null;
  loading = true;
  templateId = '';
  pharmacyId = '';
  name = '';
  questions: IQAT[] = [];
  constructor(
    private readonly store: Store,
    private readonly mqTemplateService: MQTemplateService,
    private pharmacyService: PharmacyService,
  ) {}
  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.pharmacy = await getLoginSession(this.store)
      .pipe(
        first(),
        map(s => s.pharmacy),
      )
      .toPromise();
    this.pharmacyId = this.pharmacy?.id ?? '';
    this.questions = this.takeQuestions(await this.pharmacyService.find(this.pharmacyId));
    this.loading = false;
  }

  private takeQuestions(pharmacy: IPharmacy | null): IQAT[] {
    return (
      pharmacy?.medical_questionnaire?.map(q => ({
        required: q.required,
        type: q.type,
        question: q.question,
        answers: when(q.answers)
          .on<string[]>(
            a => !a || a.length === 0,
            _ => (q.type === QuestionType.textbox ? [] : ['']),
          )
          .otherwise(a => a),
      })) ?? []
    );
  }

  async saveTemplate() {
    if (!confirm('この内容で保存しますか？')) {
      return;
    }
    const processedQuestions: IQAT[] = this.questions.map(q => ({
      required: q.required,
      type: q.type,
      question: q.question,
      answers: q.type !== QuestionType.textbox ? q.answers.filter(a => a.trim()) : [],
    }));
    try {
      this.loading = true;
      if (this.templateId) {
        await this.mqTemplateService.update({
          id: this.templateId,
          pharmacy_id: this.pharmacyId,
          qa: processedQuestions,
        });
      } else {
        await this.mqTemplateService.create({
          id: '',
          pharmacy_id: this.pharmacyId,
          qa: processedQuestions,
        });
      }
      this.questions = this.takeQuestions(await this.pharmacyService.find(this.pharmacyId));
    } catch (error) {
      console.log(error);
      alert('保存できませんでした。');
    } finally {
      this.loading = false;
    }
  }
}
