import { Injectable } from '@angular/core';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
import { IPatientInfo, IPharmacyPatientInfo } from 'src/models/patient-info';

@Injectable({
  providedIn: 'root',
})
export class PatientInfoService extends ServiceBase<any> {
  readonly propertyName = 'patient_infos';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'patient_infos');
  }

  public find(id: string): Promise<IPatientInfo> {
    return super.find(id);
  }

  public findAll(offset?: number, limit?: number, patient_account_id?: string): Promise<IPharmacyPatientInfo[]> {
    const params = {
      offset,
      limit,
      patient_account_id,
    };
    return super.findAll(params);
  }

  public findAllWithPagination(params?: { limit?: number; last_key?: string }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPharmacyPatientInfo[],
      pagination: result.pagination,
    }));
  }
}
