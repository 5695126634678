import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IPharmacist, IPharmacy } from 'src/models';
import { Session } from 'src/models/session';
import { CognitoService, configure } from 'src/services/cognito.service';
import * as SessionActions from '../actions/session.actions';

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions<SessionActions.SessionUnionActions>,
    private router: Router,
    private cognitoService: CognitoService,
    public snackbar: MatSnackBar,
  ) {}

  loginSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.loginSessions),
      switchMap(payload =>
        from(this.login(payload.payload.email, payload.payload.password, payload.payload.type)).pipe(
          map(res =>
            res
              ? SessionActions.loginSessionsSuccess({ session: new Session(res), router: this.router })
              : SessionActions.loginSessionsFailure({ error: new Error('ログインエラー') }),
          ),
          catchError(e => of(SessionActions.loadSessionsFailure)),
        ),
      ),
    ),
  );

  loadSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.loadSessions),
      switchMap(payload => {
        console.log('loadSession type', payload.payload.type);
        configure(payload.payload.type);

        return from(this.isAuthenticated(payload.payload.type)).pipe(
          map(res =>
            res
              ? SessionActions.loadSessionsSuccess({ session: new Session(res) })
              : SessionActions.loadSessionsFailure({ error: new Error('ログインエラー') }),
          ),
          catchError(e => of(SessionActions.loadSessionsFailure)),
        );
      }),
    ),
  );

  logoutSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.logoutSessions),
      switchMap(_ =>
        from(this.cognitoService.logout()).pipe(
          map(() => {
            this.router.navigate(['/']);
            return SessionActions.logoutSessionsSuccess();
          }),
          catchError(e => of(SessionActions.logoutSessionsFailure)),
        ),
      ),
    ),
  );

  private async login(username: string, password: string, type: 'pharmacy' | 'pharmacist') {
    try {
      const userId = await this.cognitoService.login(username, password, type);
      return await this.findUser(userId, type);
    } catch (error) {
      const message = (error as Error).message.trim();
      if (message) {
        this.snackbar.open(message, 'OK', {
          verticalPosition: 'top',
        });
      }
      return null;
    }
  }

  private async isAuthenticated(type: 'pharmacy' | 'pharmacist'): Promise<IPharmacy | IPharmacist | null> {
    try {
      const token = await this.cognitoService.getAccessToken();

      const userId = await this.cognitoService.isAuthenticated();

      return await this.findUser(userId, type);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private async findUser(userId: string | null, type: 'pharmacy' | 'pharmacist') {
    if (userId === null) {
      return null;
    }
    const token = await this.cognitoService.getAccessToken();
    const config = {
      headers: {
        Authorization: token.getJwtToken(),
      },
    };

    const baseUrl = environment.api_base_url;
    if (type === 'pharmacy') {
      try {
        return (await axios.get(`${baseUrl}${type}/pharmacies/${userId}`, config)).data as IPharmacy;
      } catch (error) {
        this.router.navigate(['pharmacy/registration'], {
          queryParams: { id: userId, name: (await Auth.currentUserInfo()).username },
        });
        return null;
      }
    }
    if (type === 'pharmacist') {
      try {
        return (await axios.get(`${baseUrl}${type}/pharmacists/${userId}`, config)).data as IPharmacist;
      } catch (error) {
        this.router.navigate(['pharmacist/login']);
        return null;
      }
    }
    return null;
  }
}
