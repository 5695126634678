<div class="root" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div class="top-message" fxLayout="row" fxLayoutGap="15px">
    <mat-icon fxFlexAlign="center">info_outlined</mat-icon><span>薬局情報を登録します。</span>
  </div>
  <mat-form-field class="form-field normal-width">
    <mat-label>薬局コード</mat-label>
    <input matInput type="text" [formControl]="pharmacyCodeFormControl" required />
    <mat-error *ngIf="pharmacyCodeFormControl.hasError('required')">薬局コードは<strong>必須</strong>です</mat-error>
  </mat-form-field>

  <mat-form-field class="form-field normal-width">
    <mat-label>会社名</mat-label>
    <input matInput type="text" [formControl]="companyNameFormControl" required />
    <mat-error *ngIf="companyNameFormControl.hasError('required')">薬局名は<strong>必須</strong>です</mat-error>
  </mat-form-field>
  <mat-form-field class="form-field normal-width">
    <mat-label>店舗名</mat-label>
    <input matInput type="text" [formControl]="storeNameFormControl" required />
    <mat-error *ngIf="storeNameFormControl.hasError('required')">薬局名は<strong>必須</strong>です</mat-error>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="space-between" class="zip-code-wrap">
    <mat-form-field class="form-field small-width">
      <mat-label>郵便番号 (ハイフン不要)</mat-label>
      <input matInput type="text" [formControl]="zipFormControl" required />
      <mat-error *ngIf="zipFormControl.hasError('required')">郵便番号は<strong>必須</strong>です</mat-error>
      <mat-error *ngIf="zipFormControl.hasError('pattern') && !zipFormControl.hasError('required')">
        郵便番号の形式が正しくありません
      </mat-error>
    </mat-form-field>
    <button
      fxFlexAlign="center"
      mat-stroked-button
      [disabled]="zipFormControl.hasError('pattern') || zipFormControl.hasError('required') || fetchingAddress"
      (click)="getAddress()"
    >
      住所取得
    </button>
  </div>
  <mat-form-field class="form-field normal-width">
    <mat-label>都道府県</mat-label>
    <input matInput type="text" [formControl]="prefectureFormControl" [matAutocomplete]="autoPrefecture" required />
    <mat-autocomplete #autoPrefecture="matAutocomplete">
      <mat-option *ngFor="let prefecture of filteredPrefectures | async" [value]="prefecture">
        {{ prefecture }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="prefectureFormControl.hasError('required')">都道府県は<strong>必須</strong>です</mat-error>
    <mat-error *ngIf="prefectureFormControl.hasError('pattern') && !prefectureFormControl.hasError('required')">
      都道府県を入力してください
    </mat-error>
  </mat-form-field>
  <mat-form-field class="form-field normal-width">
    <mat-label>住所1</mat-label>
    <input matInput type="text" [formControl]="address1FormControl" required />
    <mat-error *ngIf="address1FormControl.hasError('required')">住所1は<strong>必須</strong>です</mat-error>
  </mat-form-field>
  <mat-form-field class="form-field normal-width">
    <mat-label>住所2</mat-label>
    <input matInput type="text" [formControl]="address2FormControl" required />
    <mat-error *ngIf="address2FormControl.hasError('required')">住所2は<strong>必須</strong>です</mat-error>
  </mat-form-field>
  <mat-form-field class="form-field normal-width">
    <mat-label>住所3</mat-label>
    <input matInput type="text" [formControl]="address3FormControl" />
  </mat-form-field>

  <mat-form-field class="form-field normal-width">
    <mat-label>Eメールアドレス</mat-label>
    <input matInput type="email" [formControl]="emailFormControl" required />
    <mat-error *ngIf="emailFormControl.hasError('required')">メールアドレスは<strong>必須</strong>です</mat-error>
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
      メールアドレスの形式が正しくありません
    </mat-error>
  </mat-form-field>
  <mat-form-field class="form-field normal-width">
    <mat-label>ホームページ</mat-label>
    <input matInput type="text" [(ngModel)]="web" />
  </mat-form-field>
  <mat-form-field class="form-field normal-width">
    <mat-label>電話番号 (ハイフン不要)</mat-label>
    <input matInput type="tel" [formControl]="telFormControl" required />
    <mat-error *ngIf="telFormControl.hasError('required')">電話番号は<strong>必須</strong>です</mat-error>
    <mat-error *ngIf="telFormControl.hasError('pattern') && !telFormControl.hasError('required')">
      電話番号の形式が正しくありません
    </mat-error>
  </mat-form-field>
  <div fxLayout="column" fxLayoutGap="10px">
    <div>薬局位置情報</div>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field class="form-field small-width">
        <mat-label>緯度</mat-label>
        <input matInput type="text" [formControl]="latitudeFormControl" />
        <mat-error *ngIf="latitudeFormControl.hasError('pattern')">
          緯度の形式が正しくありません
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field small-width">
        <mat-label>経度</mat-label>
        <input matInput type="text" [formControl]="longitudeFormControl" />
        <mat-error *ngIf="longitudeFormControl.hasError('pattern')">
          経度の形式が正しくありません
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <loading-button
    [name]="'登録'"
    [loading]="loading"
    [color]="'primary'"
    [disabled]="!areAllFormsValid"
    (click)="register()"
  ></loading-button>
</div>
