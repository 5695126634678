<div class="chat-area">
  <div class="pre-wrap" id="chat">
    <div *ngFor="let post of posts; index as i" class="post-wrapper">
      <p class="post" [class]="post.isSelf ? 'self' : 'patient'" [id]="'chat-' + i">
        <span>{{ post.message }}</span>
      </p>
    </div>
  </div>
  <div class="form-field">
    <mat-form-field>
      <input type="text" matInput [(ngModel)]="text" (keydown.enter)="sendMessage($event)" />
      <mat-icon matSuffix (click)="sendMessage($event)">mode_edit</mat-icon>
    </mat-form-field>
  </div>
</div>
