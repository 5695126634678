import { Component, OnInit } from '@angular/core';
import { ReservationSlotService } from 'src/services/api/reservation-slot.service';
import { IReservationSlot } from '../../../models';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-reservation-slots',
  templateUrl: './reservation-slots.component.html',
  styleUrls: ['./reservation-slots.component.scss'],
})
export class ReservationSlotsComponent implements OnInit {
  reservationSlots: IReservationSlot[] = [];
  pharmacyId = '';

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 20;
  private lastKeys: string[] = [];

  loading = true;
  constructor(private router: Router, private reservationSlotService: ReservationSlotService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      await this.fetchReservationSlots();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  private async fetchReservationSlots(params?: { last_key?: string }) {
    const pagination = await this.reservationSlotService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(data => {
        this.reservationSlots = data.reservations;
        return data.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchReservationSlots({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }

  toDetailPage(slotId: string) {
    this.router.navigate(['pharmacy/reservation-slots/detail'], { queryParams: { slotId } });
  }
}
