<div>
  <div class="top-area" fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="50" class="input-area">
      <mat-form-field>
        <mat-label for="name">名前で検索</mat-label>
        <input type="text" matInput [formControl]="nameFilterFormControl" />
      </mat-form-field>
    </div>
    <div fxFlexAlign="center">
      <button mat-raised-button color="primary" (click)="openCreatePharmacistDialog()">薬剤師登録</button>
    </div>
  </div>

  <div class="table-container">
    <div class="paginator-and-table">
      <mat-paginator
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
      ></mat-paginator>
      <table mat-table [dataSource]="pharmacists">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>氏名</th>
          <td mat-cell *matCellDef="let element">{{ element.family_name + element.given_name }}</td>
        </ng-container>

        <ng-container matColumnDef="kana">
          <th mat-header-cell *matHeaderCellDef>かな</th>
          <td mat-cell *matCellDef="let element">{{ element.family_name_kana + element.given_name_kana }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          class="clickable-row"
          [class.unclickable]="pharmacistsDisabled[i]"
          (click)="openDetailDialog(row)"
          *matRowDef="let row; columns: displayedColumns; index as i"
        ></tr>
      </table>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
