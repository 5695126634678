import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPharmacist } from 'src/models/pharmacist';
import { PharmacistService } from 'src/services/api/pharmacist.service';
import { getLoginSession } from 'src/app/modules/storeModules';
import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pharmacists',
  templateUrl: './pharmacists.component.html',
  styleUrls: ['./pharmacists.component.scss'],
})
export class PharmacistsComponent implements OnInit, OnDestroy {
  pharmacists: IPharmacist[] = [];
  pharmacistsDisabled: boolean[] = [];
  private pharmacyId = '';
  readonly displayedColumns: readonly string[] = ['name', 'kana'];
  nameFilterFormControl = new FormControl('');

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 20;
  private lastKeys: string[] = [];

  loading = true;
  private formControlSubscription: Subscription;

  constructor(public dialog: MatDialog, private store: Store, private pharmacistService: PharmacistService) {
    this.formControlSubscription = this.nameFilterFormControl.valueChanges.subscribe(_ => this.filterByName());
  }

  async ngOnInit() {
    this.pharmacyId = await getLoginSession(this.store)
      .pipe(
        filter(isNotNull),
        first(),
        map(s => s.pharmacy?.id ?? ''),
      )
      .toPromise();
    try {
      await this.fetchPharmacists();
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.formControlSubscription.unsubscribe();
  }

  private async fetchPharmacists(params?: { last_key?: string }) {
    const pagination = await this.pharmacistService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(data => {
        this.pharmacists = data.pharmacists;
        return data.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
    this.filterByName();
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchPharmacists({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }

  private filterByName() {
    const filters = (this.nameFilterFormControl.value as string).trim().split(' ');
    this.pharmacistsDisabled = this.pharmacists.map(
      p =>
        !(
          (filters.length === 1 && !filters[0]) ||
          filters.every(f => (p.family_name + p.given_name).includes(f)) ||
          filters.every(f => (p.family_name_kana + p.given_name_kana).includes(f))
        ),
    );
  }

  async openDetailDialog(pharmacist: IPharmacist) {
    const dialogRef = this.dialog.open(PharmacistDetailDialog, {
      data: { pharmacist, pharmacyId: this.pharmacyId },
      minWidth: 400,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result: ${result}`);
      if (result?.needFetch) {
        await this.fetchPharmacists();
      }
    });
  }

  async openCreatePharmacistDialog() {
    const dialogRef = this.dialog.open(PharmacistDetailDialog, {
      data: { pharmacist: null, pharmacyId: this.pharmacyId },
      minWidth: 400,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result: ${result}`);
      if (result?.needFetch) {
        await this.fetchPharmacists();
      }
    });
  }
}

@Component({
  selector: 'pharmacist-detail-dialog',
  templateUrl: './pharmacist-detail-dialog.html',
  styleUrls: ['./pharmacists.component.scss'],
})
export class PharmacistDetailDialog {
  pharmacist: IPharmacist | null;
  pharmacyId: string;
  saveButtonLoading = false;
  deleteButtonLoading = false;
  newPassword = '';

  loginIdFormControl = new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')]);
  familyNameFormControl = new FormControl('', [Validators.required]);
  givenNameFormControl = new FormControl('', [Validators.required]);
  familyNameKanaFormControl = new FormControl('', [Validators.required, Validators.pattern('^[\u3040-\u309fー・]+$')]);
  givenNameKanaFormControl = new FormControl('', [Validators.required, Validators.pattern('^[\u3040-\u309fー・]+$')]);
  emailFormControl = new FormControl('', [Validators.email]);

  get buttonName(): '変更' | '登録' {
    if (this.pharmacist) {
      return '変更';
    }
    return '登録';
  }

  get areAllFormsValid() {
    const exceptUserName = [
      this.familyNameFormControl,
      this.givenNameFormControl,
      this.familyNameKanaFormControl,
      this.givenNameKanaFormControl,
      this.emailFormControl,
    ].every(c => c.valid);
    return this.pharmacist ? exceptUserName : exceptUserName && this.loginIdFormControl.valid;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<PharmacistDetailDialog>,
    @Inject(MAT_DIALOG_DATA) data: { pharmacist: IPharmacist | null; pharmacyId: string },
    private pharmacistService: PharmacistService,
  ) {
    this.dialogRef.disableClose = true;
    this.pharmacist = data.pharmacist ?? null;
    this.pharmacyId = data.pharmacyId;
    this.familyNameFormControl.setValue(this.pharmacist?.family_name ?? '');
    this.familyNameKanaFormControl.setValue(this.pharmacist?.family_name_kana ?? '');
    this.givenNameFormControl.setValue(this.pharmacist?.given_name ?? '');
    this.givenNameKanaFormControl.setValue(this.pharmacist?.given_name_kana ?? '');
    this.emailFormControl.setValue(this.pharmacist?.email ?? '');
  }

  async save() {
    try {
      this.saveButtonLoading = true;
      if (this.pharmacist) {
        await this.update();
      } else {
        await this.create();
      }
    } finally {
      this.saveButtonLoading = false;
    }
  }

  private async update() {
    if (!this.pharmacist || !confirm(`ID: ${this.pharmacist.family_name} さんの情報を変更します。よろしいですか？`)) {
      return;
    }
    try {
      this.disableAllForms();
      await this.pharmacistService.update({
        id: this.pharmacist.id,
        family_name: this.familyNameFormControl.value as string,
        family_name_kana: this.familyNameKanaFormControl.value as string,
        given_name: this.givenNameFormControl.value as string,
        given_name_kana: this.givenNameKanaFormControl.value as string,
        email: this.emailFormControl.value as string,
      });
      this.dialogRef.close({ needFetch: true });
    } catch (error) {
      alert('薬剤師更新に失敗しました。');
      throw error;
    } finally {
      this.enableAllForms();
    }
  }

  private async create() {
    if (!confirm(`新しい薬剤師を登録します。よろしいですか？`)) {
      return;
    }
    try {
      this.disableAllForms();
      this.newPassword = await this.pharmacistService.createPharmacist({
        id: '',
        username: this.loginIdFormControl.value as string,
        given_name: this.givenNameFormControl.value as string,
        given_name_kana: this.givenNameKanaFormControl.value as string,
        family_name: this.familyNameFormControl.value as string,
        family_name_kana: this.familyNameKanaFormControl.value as string,
        pharmacy_id: this.pharmacyId,
        email: this.emailFormControl.value as string,
      });
    } catch (error) {
      alert('薬剤師登録に失敗しました。');
      throw error;
    } finally {
      this.enableAllForms();
    }
  }

  close() {
    if (confirm('この画面を閉じるとパスワードは２度と表示されません！閉じてもよろしいですか？')) {
      this.dialogRef.close({ needFetch: true });
    }
  }

  async deletePharmacist() {
    if (!this.pharmacist || !confirm(`${this.pharmacist.family_name} さんを削除します。よろしいですか？`)) {
      return;
    }
    try {
      this.disableAllForms();
      this.deleteButtonLoading = true;
      await this.pharmacistService.remove(this.pharmacist.id);
    } catch (error) {
      if (error.response.status === 403) {
        alert('服薬指導を担当している薬剤師を削除することはできません。');
      } else {
        alert('薬剤師削除に失敗しました。');
      }
      throw error;
    } finally {
      this.deleteButtonLoading = false;
      this.enableAllForms();
    }
    this.dialogRef.close({ needFetch: true });
  }

  disableAllForms() {
    [
      this.loginIdFormControl,
      this.familyNameFormControl,
      this.givenNameFormControl,
      this.familyNameKanaFormControl,
      this.givenNameKanaFormControl,
      this.emailFormControl,
    ].forEach(c => c.disable());
  }

  enableAllForms() {
    [
      this.loginIdFormControl,
      this.familyNameFormControl,
      this.givenNameFormControl,
      this.familyNameKanaFormControl,
      this.givenNameKanaFormControl,
      this.emailFormControl,
    ].forEach(c => c.enable());
  }
}
