import { Store } from '@ngrx/store';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { environment } from 'src/environments/environment';
import { CognitoService } from './cognito.service';

type ContentType = 'application/json';
type Pagination = { totalrecords: number; displayrecords: number; last_key?: string };

export abstract class ServiceBase<T> {
  constructor(private cognito: CognitoService, private store: Store, apiPath: string) {
    this.urlObserver = getLoginSession(store).pipe(
      map(session => {
        if (session.type === undefined) {
          throw new Error('typeが指定されていません');
        }
        return environment.api_base_url + session.type + '/' + apiPath;
      }),
    );
  }
  abstract propertyName: string;
  private urlObserver: Observable<string>;

  protected async findAll(params?: {}): Promise<T[]> {
    return this.urlObserver
      .pipe(
        switchMap(async url => {
          const result = this.get<T[]>(url, params);
          console.log('ServiceBase findAll', url, await result);
          return await result;
        }),
        first(),
      )
      .toPromise();
  }

  protected async findAllWithPagination(params?: {}): Promise<
    { [x: string]: (T[] | undefined) | Pagination } & { pagination: Pagination }
  > {
    return this.urlObserver
      .pipe(
        switchMap(async url => {
          const result = await this.getWithPagination<T[]>(url, params);
          console.log('ServiceBase findAll', url, result);
          return result;
        }),
        first(),
      )
      .toPromise();
  }

  public async find(id: string): Promise<T | null> {
    return this.urlObserver
      .pipe(
        map(url =>
          this.get<T>(`${url}/${id}`).catch(e => {
            if (e.response.status === 404) {
              return null;
            }
            throw e;
          }),
        ),
        first(),
      )
      .toPromise();
  }

  public async create(data: T): Promise<any> {
    return this.urlObserver
      .pipe(
        map(url => this.post<any>(url, data)),
        first(),
      )
      .toPromise();
  }

  public async update(data: Partial<T> & { id: string }): Promise<any> {
    return this.urlObserver
      .pipe(
        map(url => this.put<any>(url + '/' + data.id, data)),
        first(),
      )
      .toPromise();
  }

  public async remove(id: string): Promise<any> {
    return this.urlObserver
      .pipe(
        map(async url => {
          const result = await this.delete<any>(url + '/' + id);
          return result.data.chef;
        }),
        first(),
      )
      .toPromise();
  }

  private async get<T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<T> {
    const paramText = createQueryParameter(params)?.toString();
    url = paramText ? `${url}?${paramText}` : url;

    const result = (await axios.get<any>(url, await this.buildConfig(config))).data;
    if (result.pagination || (result.totalrecords !== null && result.totalrecords) !== undefined) {
      return result[this.propertyName] as T;
    }
    return result as T;
  }

  private async getWithPagination<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig,
  ): Promise<{ [x: string]: T | undefined | Pagination; pagination: Pagination }> {
    const paramText = createQueryParameter(params)?.toString();
    url = paramText ? `${url}?${paramText}` : url;

    const result = (await axios.get<any>(url, await this.buildConfig(config))).data;
    return { [this.propertyName]: result[this.propertyName] as T, pagination: result.pagination };
  }

  private async post<T>(url: string, data: any, config?: AxiosRequestConfig) {
    return axios.post<T>(url, data, await this.buildConfig(config, 'application/json'));
  }

  private async put<T>(url: string, data: any, config?: AxiosRequestConfig) {
    return axios.put<T>(url, data, await this.buildConfig(config, 'application/json'));
  }

  private async delete<T>(url: string, config?: AxiosRequestConfig) {
    return axios.delete<T>(url, await this.buildConfig(config));
  }

  protected async buildConfig(
    config: AxiosRequestConfig | undefined,
    contentType?: ContentType,
  ): Promise<AxiosRequestConfig> {
    config = config ? config : {};
    if (!config.headers) {
      config.headers = {};
    }

    const token = await this.cognito.getAccessToken();
    config.headers.Authorization = token.getJwtToken();

    if (contentType) {
      config.headers['Content-Type'] = contentType;
    }
    return config;
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log('clientService: ' + message);
  }
}

export const createQueryParameter = (data: any): URLSearchParams | null => {
  if (!data) {
    return null;
  }
  const queryObject: any = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'number') {
      queryObject[key] = value;
    }
    if (typeof value === 'string' && value) {
      queryObject[key] = value;
    }
  }

  return new URLSearchParams(queryObject);
};
