import { animate, style, transition, trigger } from '@angular/animations';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, InjectionToken, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { when } from 'src/app/modules/when';
import { IPCF } from 'src/models/pcf';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PcfService } from 'src/services/api/pcf.service';
import { CognitoService } from 'src/services/cognito.service';

const STATE_FILTER = new InjectionToken<boolean[]>('STATE_FILTER');
type PCFForDisplaySort = (a: PCFForDisplay, b: PCFForDisplay) => number;
type followStatus = '終了' | '確認済み' | '回答済み' | '送信済み' | '送信予約済み' | '下書き';
@Component({
  selector: 'app-follow-list',
  templateUrl: './follow-list.component.html',
  styleUrls: ['./follow-list.component.scss'],
})
export class FollowListComponent implements OnInit {
  loading = true;
  followsForDisplay: PCFForDisplay[] = [];
  sortConfig: [-1 | 0 | 1, -1 | 0 | 1] = [0, 0];
  stateFilter = [true, true, true, true, true, false];
  readonly columnProportion: number[] = [25, 15, 20, 15, 25];
  readonly columnProportionLtMd: number[] = [27, 23, 23, 27];
  readonly timeDisplayFormat = 'yyyy/MM/dd HH:mm';

  get filteredFollows(): PCFForDisplay[] {
    return this.followsForDisplay.filter(this.filterFunc).sort(this.sortFunc);
  }

  constructor(
    private pcfService: PcfService,
    private patientInfoService: PatientInfoService,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.fetchFollows();
    this.loading = false;
  }

  async fetchFollows(): Promise<void> {
    return Promise.all([
      this.pcfService.findAll({ status: undefined, patient_info_id: undefined }),
      this.patientInfoService.findAll().then(result => result.map(i => i.patient_info)),
    ]).then(result => {
      this.followsForDisplay = result[0].map(follow => {
        const info = result[1].find(i => i.id === follow.patient_info_id);
        return {
          ...follow,
          patient_name: `${info?.family_name ?? ''} ${info?.given_name ?? ''}`,
          tel: info?.tel ?? '',
          status: when(follow)
            .on<followStatus>(
              f => f.finished || f.finished !== undefined,
              _ => '終了',
            )
            .on<followStatus>(
              f => f.confirmed_at !== null && f.confirmed_at !== undefined,
              _ => '確認済み',
            )
            .on<followStatus>(
              f => f.answered_at !== null && f.answered_at !== undefined,
              _ => '回答済み',
            )
            .on<followStatus>(
              f => f.sent_at !== null && f.sent_at !== undefined,
              _ => '送信済み',
            )
            .on<followStatus>(
              f => f.send_at !== null && f.send_at !== undefined,
              _ => '送信予約済み',
            )
            .otherwise<followStatus>(_ => '下書き'),
          original: follow,
        };
      });
    });
  }

  private get filterFunc(): (f: PCFForDisplay) => boolean {
    const displayedState = ['下書き', '送信予約済み', '送信済み', '回答済み', '確認済み', '終了'].filter(
      (s, i) => this.stateFilter[i],
    );
    return f => displayedState.includes(f.status);
  }

  private get sortFunc(): PCFForDisplaySort {
    const target = (() => {
      const _target = this.sortConfig.findIndex(d => d);
      return _target < 0 ? 0 : _target;
    })();
    const direction = this.sortConfig[target] ? this.sortConfig[target] : 1;
    return when(target)
      .on(
        v => v === 1,
        _ => (a: PCFForDisplay, b: PCFForDisplay) => {
          if (a.answered_at !== null && a.answered_at !== undefined) {
            if (b.answered_at !== null && b.answered_at !== undefined) {
              return (a.answered_at - b.answered_at) * direction;
            }
            return -1;
          }
          return 1;
        },
      )
      .otherwise(_ => (a: PCFForDisplay, b: PCFForDisplay) => {
        if (a.send_at !== null && a.send_at !== undefined) {
          if (b.send_at !== null && b.send_at !== undefined) {
            return (a.send_at - b.send_at) * direction;
          }
          return 1;
        }
        return -1;
      });
  }

  sortChange(sortBy: 0 | 1) {
    if (this.sortConfig[sortBy] === 1) {
      this.sortConfig[sortBy] = -1;
    } else if (this.sortConfig[sortBy] === -1) {
      this.sortConfig[sortBy] = 0;
    } else {
      this.sortConfig.fill(0)[sortBy] = 1;
    }
  }

  openDialog(row: PCFForDisplay) {
    const dialogRef = this.dialog.open(FollowDetailDialog, { data: row, minWidth: 450 });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.fetchFollows();
    });
  }

  showController(origin: ElementRef): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]);
    const controllerRef = this.overlay.create({
      scrollStrategy,
      positionStrategy,
      width: 'auto',
      height: 'auto',
      hasBackdrop: true,
      backdropClass: ['controller-backdrop'],
    });
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: STATE_FILTER, useValue: this.stateFilter }],
    });
    controllerRef.attach(new ComponentPortal(StateFilterControllerComponent, null, injector));
    controllerRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(_ => controllerRef.dispose());
  }
}

@Component({
  selector: 'follow-detail-dialog',
  templateUrl: './follow-detail-dialog.html',
  styleUrls: ['./follow-list.component.scss'],
})
export class FollowDetailDialog {
  loading = false;
  constructor(
    private pcfService: PcfService,
    public dialogRef: MatDialogRef<FollowDetailDialog>,
    private cognito: CognitoService,
    @Inject(MAT_DIALOG_DATA) public readonly follow: PCFForDisplay,
  ) {}

  private async config() {
    return {
      headers: {
        Authorization: (await this.cognito.getAccessToken()).getJwtToken(),
        'Content-Type': 'application/json',
      },
    };
  }

  edit() {}
  async confirm() {
    try {
      this.loading = true;
      await this.pcfService.confirm(this.follow.original, await this.config());
      this.dialogRef.close();
    } finally {
      this.loading = false;
    }
  }
  finalize() {
    try {
      this.loading = true;
      this.pcfService.finalize(this.follow.original);
    } finally {
      this.loading = false;
    }
  }
  async cancel() {
    try {
      this.loading = true;
      await this.pcfService.cancel(this.follow.original, await this.config());
      this.dialogRef.close();
    } finally {
      this.loading = false;
    }
  }
  async delete() {
    try {
      this.loading = true;
      await this.pcfService.remove(this.follow.original.id);
      this.dialogRef.close();
    } finally {
      this.loading = false;
    }
  }
}

@Component({
  selector: 'state-filter-controller',
  templateUrl: './state-filter-controller-component.html',
  styleUrls: ['./follow-list.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.2s 0s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class StateFilterControllerComponent {
  readonly statusNames = ['下書き', '送信予約済み', '送信済み', '回答済み', '確認済み', '終了'];
  constructor(@Inject(STATE_FILTER) public stateFilter: boolean[]) {}
}

interface PCFForDisplay extends IPCF {
  patient_name: string;
  tel: string;
  status: followStatus;
  original: IPCF;
}
