import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { IPharmacy } from 'src/models';
import { filteredPrefectures, prefectures } from 'src/models/prefectures';
import { PharmacyService } from 'src/services/api/pharmacy.service';
import { ZipToAddressService } from 'src/services/zip-to-address.service';

@Component({
  selector: 'app-pharmacy-profile',
  templateUrl: './pharmacy-profile.component.html',
  styleUrls: ['./pharmacy-profile.component.scss'],
})
export class PharmacyProfileComponent implements OnInit {
  pharmacyCode = '';
  readonly companyNameFormControl = new FormControl('', [Validators.required]);
  readonly storeNameFormControl = new FormControl('', [Validators.required]);
  readonly zipFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{7}$')]);
  readonly prefectureFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(' + prefectures.reduce((acc: string, cur) => acc + cur + '|', '').slice(0, -1) + ')$'),
  ]);
  readonly address1FormControl = new FormControl('', [Validators.required]);
  readonly address2FormControl = new FormControl('', [Validators.required]);
  readonly address3FormControl = new FormControl('');
  readonly telFormControl = new FormControl('', [Validators.required, Validators.pattern('^0[0-9]{9,10}$')]);
  readonly emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  readonly webFormControl = new FormControl('', [Validators.required]);
  readonly latitudeFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[+-]?[0-9]+[.][0-9]+([eE][+-]?[0-9]+)?$'),
  ]);
  readonly longitudeFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[+-]?[0-9]+[.][0-9]+([eE][+-]?[0-9]+)?$'),
  ]);

  private pharmacyId = '';
  private pharmacy?: IPharmacy;
  loading = true;
  fetchingAddress = false;
  isActive = false;
  filteredPrefectures: Observable<string[]> = of([]);

  constructor(
    private readonly store: Store,
    private readonly pharmacyService: PharmacyService,
    private readonly zipToAddressService: ZipToAddressService,
  ) {
    this.filteredPrefectures = this.prefectureFormControl.valueChanges.pipe(map((v: string) => filteredPrefectures(v)));
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.pharmacy = await getLoginSession(this.store)
      .pipe(
        filter(isNotNull),
        first(),
        map(s => s.pharmacy ?? undefined),
      )
      .toPromise();
    this.pharmacyId = this.pharmacy?.id ?? '';
    const data = await this.pharmacyService.find(this.pharmacyId);
    if (data) {
      this.pharmacyCode = data.pharmacy_code;
      this.companyNameFormControl.setValue(data.company_name);
      this.storeNameFormControl.setValue(data.store_name);
      this.zipFormControl.setValue(data.zip ?? '');
      this.prefectureFormControl.setValue(data.prefecture ?? '');
      this.address1FormControl.setValue(data.address1 ?? '');
      this.address2FormControl.setValue(data.address2 ?? '');
      this.address3FormControl.setValue(data.address3 ?? '');
      this.telFormControl.setValue(data.tel ?? '');
      this.emailFormControl.setValue(data.email ?? '');
      this.webFormControl.setValue(data.web ?? '');
      this.latitudeFormControl.setValue(data.location?.latitude ?? '');
      this.longitudeFormControl.setValue(data.location?.longitude ?? '');
      this.isActive = data.is_active === 1;
    }
    this.loading = false;
  }

  get areAllFormsValid() {
    return [
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.telFormControl,
      this.emailFormControl,
      this.webFormControl,
      this.latitudeFormControl,
      this.longitudeFormControl,
    ].every(c => c.valid);
  }

  get noMqTemplate() {
    return this.pharmacy?.medical_questionnaire === null || this.pharmacy?.medical_questionnaire === undefined;
  }

  async getAddress() {
    this.fetchingAddress = true;
    await this.zipToAddressService
      .getAddress(this.zipFormControl.value as string)
      .then(result => {
        if (result.notFound) {
          alert('該当する住所が見つかりませんでした。');
          return;
        }
        this.prefectureFormControl.setValue(result.prefecture);
        this.address1FormControl.setValue(result.address);
      })
      .catch(error => {
        console.log(error);
        return;
      });
    this.fetchingAddress = false;
  }

  async save() {
    if (!confirm('この内容で登録しますか？')) {
      return;
    }
    this.loading = true;
    this.disableAllForms();
    try {
      await this.pharmacyService.update({
        id: this.pharmacyId,
        company_name: this.companyNameFormControl.value,
        store_name: this.storeNameFormControl.value,
        zip: this.zipFormControl.value,
        prefecture: this.prefectureFormControl.value,
        address1: this.address1FormControl.value,
        address2: this.address2FormControl.value,
        address3: this.address3FormControl.value,
        tel: this.telFormControl.value,
        email: this.emailFormControl.value,
        web: this.webFormControl.value,
        location: {
          latitude: Number(this.latitudeFormControl.value),
          longitude: Number(this.longitudeFormControl.value),
        },
      });
    } catch (error) {
      alert('登録に失敗しました');
      throw new Error('');
    } finally {
      this.loading = false;
      this.enableAllForms();
    }
  }

  async activatePharmacy() {
    this.loading = true;
    if (!confirm('この薬局を公開しますか？公開すると、患者アプリの検索結果に表示されるようになります。')) {
      this.loading = false;
      return;
    }
    try {
      if (!this.areAllFormsValid) {
        alert('入力されていない項目があります！すべての項目を入力しないと公開できません。');
        return;
      }
      await this.save();
      await this.pharmacyService.activate(this.pharmacyId);
      this.isActive = true;
    } catch (error) {
      console.log(error);
      alert('薬局を公開できませんでした');
    } finally {
      this.loading = false;
    }
  }

  async deactivatePharmacy() {
    if (!confirm('この薬局を無効化しますか？')) {
      return;
    }
    this.loading = true;
    try {
      await this.pharmacyService.deactivate(this.pharmacyId);
      this.isActive = false;
    } catch (error) {
      console.log(error);
      alert('薬局を無効化できませんでした');
    } finally {
      this.loading = false;
    }
  }

  private disableAllForms() {
    [
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.telFormControl,
      this.emailFormControl,
      this.webFormControl,
      this.latitudeFormControl,
      this.longitudeFormControl,
    ].forEach(c => c.disable());
  }
  private enableAllForms() {
    [
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.telFormControl,
      this.emailFormControl,
      this.webFormControl,
      this.latitudeFormControl,
      this.longitudeFormControl,
    ].forEach(c => c.enable());
  }
}
