<div class="state-filter-controller-root" [@fadeInAnimation]="">
  <div fxLayout="column">
    <div
      class="choice clickable"
      *ngFor="let statusName of statusNames; index as i;"
      (click)="stateFilter[i]=!stateFilter[i]"
      fxLayout="row"
      fxLayoutGap="15px"
      matRipple
    >
      <mat-pseudo-checkbox
        class="mat-pseudo-checkbox mat-option-pseudo-checkbox ng-star-inserted"
        [state]="stateFilter[i]?'checked':'unchecked'"
        fxFlexAlign="center"
      ></mat-pseudo-checkbox>
      <div fxFlexAlign="center">{{statusName}}</div>
    </div>
  </div>
</div>
