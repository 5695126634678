<h1 mat-dialog-title>処方箋の再送要求</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="10px" class="dialog-content">
  <mat-radio-group fxLayout="column" fxLayoutGap="10px" [(ngModel)]="statusReason">
    <mat-radio-button *ngFor="let reason of requestResendMessages index as i" [value]="i">{{reason}}</mat-radio-button>
  </mat-radio-group>
  <mat-form-field>
    <mat-label>患者へのメッセージを入力</mat-label>
    <textarea matInput [(ngModel)]="message"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button mat-button mat-dialog-close>閉じる</button>
  <button mat-button color="accent" (click)="requestResend()">再送要求</button>
</div>
