import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { IPCF } from 'src/models/pcf';
import { QuestionType } from 'src/models/qa-template';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class PcfService extends ServiceBase<IPCF> {
  propertyName = 'pcfs';
  dummyData = [
    {
      id: 'b2a55f2a-975c-0721-e9da-a3941af5e8d3',
      pharmacy_id: 'a4793d34-8207-4fb3-bbe6-59e5955312d1',
      pharmacist_id: 'cc7f85f4-9fe0-4fab-8d29-96b03220f249',
      patient_account_id: 'ddfb06c8-f2fc-4e35-a724-436a015a0009',
      patient_info_id: '44dc0757-cd5b-180b-3b25-1d81c558966a',
      questions: [
        {
          type: QuestionType.radio,
          required: true,
          question: '薬を飲みましたか',
          answers: ['はい', 'いいえ'],
        },
        {
          type: QuestionType.radio,
          required: true,
          question: '副作用はありますか',
          answers: ['はい', 'いいえ'],
        },
        {
          type: QuestionType.textbox,
          required: false,
          question: 'その他自由',
          answers: [],
        },
      ],
      answers: ['はい', 'いいえ', ''],
      send_at: new Date('2021-06-17 10:00:00').getTime(),
      sent_at: new Date('2021-06-17 10:00:01').getTime(),
      first_read_at: new Date('2021-06-17 11:12:30').getTime(),
      last_read_at: new Date('2021-06-17 11:12:30').getTime(),
      confirmed_at: new Date('2021-06-18 09:05:02').getTime(),
      answered_at: new Date('2021-06-17 11:16:21').getTime(),
      finished: false,
      created_at: new Date('2021-06-15 16:48:05').getTime(),
      updated_at: new Date('2021-06-18 09:05:02').getTime(),
    },
    {
      id: '685f357d-4162-f2a9-098a-5675a325cc77',
      pharmacy_id: 'a4793d34-8207-4fb3-bbe6-59e5955312d1',
      pharmacist_id: 'cc7f85f4-9fe0-4fab-8d29-96b03220f249',
      patient_account_id: 'ddfb06c8-f2fc-4e35-a724-436a015a0009',
      patient_info_id: '44dc0757-cd5b-180b-3b25-1d81c558966a',
      questions: [
        {
          type: QuestionType.radio,
          required: true,
          question: '薬を飲みましたか',
          answers: ['はい', 'いいえ'],
        },
      ],
      finished: false,
      created_at: new Date('2021-06-15 13:08:55').getTime(),
      updated_at: new Date('2021-06-15 13:08:55').getTime(),
    },
    {
      id: 'd53ab4d4-8999-8676-459b-126d349122d5',
      pharmacy_id: 'a4793d34-8207-4fb3-bbe6-59e5955312d1',
      pharmacist_id: 'cc7f85f4-9fe0-4fab-8d29-96b03220f249',
      patient_account_id: 'ddfb06c8-f2fc-4e35-a724-436a015a0009',
      patient_info_id: '44dc0757-cd5b-180b-3b25-1d81c558966a',
      questions: [
        {
          type: QuestionType.radio,
          required: true,
          question: '薬を飲みましたか',
          answers: ['はい', 'いいえ'],
        },
      ],
      answers: ['はい'],
      send_at: new Date('2021-06-17 10:01:00').getTime(),
      sent_at: new Date('2021-06-17 10:01:01').getTime(),
      first_read_at: new Date('2021-06-17 11:13:30').getTime(),
      last_read_at: new Date('2021-06-17 11:13:30').getTime(),
      answered_at: new Date('2021-06-17 11:19:21').getTime(),
      finished: false,
      created_at: new Date('2021-06-15 16:56:05').getTime(),
      updated_at: new Date('2021-06-17 11:19:21').getTime(),
    },
  ];

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pcfs');
  }

  // override
  public find(id: string): Promise<IPCF | null> {
    return super.find(id);
  }

  // override
  public create(pcf: IPCF): Promise<void> {
    return super.create(pcf);
  }

  public findAll(params?: { status?: string; patient_info_id?: string }): Promise<IPCF[]> {
    return super.findAll(params);
  }

  public async confirm(follow: IPCF, config: AxiosRequestConfig) {
    await axios.post(environment.api_base_url + `pharmacist/pcfs/${follow.id}/confirm`, undefined, config);
  }

  public finalize(follow: IPCF) {
    follow.finished = true;
    // super.update(follow);
  }

  public async cancel(follow: IPCF, config: AxiosRequestConfig) {
    await axios.post(environment.api_base_url + `pharmacist/pcfs/${follow.id}/cancel`, undefined, config);
  }
}
