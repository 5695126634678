<div>
  <form>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <mat-form-field>
        <mat-label for="name">名前で検索</mat-label>
        <input type="text" matInput name="name" [formControl]="nameFilterFormControl" />
      </mat-form-field>
    </div>
  </form>

  <div class="table-container">
    <div class="paginator-and-table">
      <mat-paginator
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
      ></mat-paginator>
      <table mat-table [dataSource]="pharmacyPatientInfos">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>名前</th>
          <td mat-cell *matCellDef="let element">{{ getPatientName(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="birth_date">
          <th mat-header-cell *matHeaderCellDef>生年月日</th>
          <td mat-cell *matCellDef="let element">{{ element.patient_info.birth_date | date: 'yyyy/MM/dd' }}</td>
        </ng-container>

        <ng-container matColumnDef="sex">
          <th mat-header-cell *matHeaderCellDef>性別</th>
          <td mat-cell *matCellDef="let element">{{ element.patient_info.sex | patient_sex }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          class="clickable-row"
          [class.unclickable]="patientsDisabled[i]"
          routerLink="/pharmacist/patients/{{ row.patient_info.id }}"
          disabled
          *matRowDef="let row; columns: displayedColumns; index as i"
        ></tr>
      </table>
    </div>
    <div *ngIf="loading" class="loading-shade">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
