<audio style="display: none" id="meeting-audio"></audio>
<div class="detail">
  <table mat-table [dataSource]="pci ? [pci] : []">
    <ng-container matColumnDef="timeText">
      <th mat-header-cell *matHeaderCellDef>開始日時</th>
      <td mat-cell *matCellDef="let element">{{ element.reservation }}</td>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>患者名</th>
      <td mat-cell *matCellDef="let element">{{ element.patientName }}</td>
    </ng-container>

    <ng-container matColumnDef="pharmacistName">
      <th mat-header-cell *matHeaderCellDef>薬剤師名</th>
      <td mat-cell *matCellDef="let element">{{ element.pharmacistName }}</td>
    </ng-container>

    <ng-container matColumnDef="wasConfirmed">
      <th mat-header-cell *matHeaderCellDef>予約確認</th>
      <td mat-cell *matCellDef="let element">{{ element.status | reservation_status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div fxLayout="row" class="pci-wrapper">
  <div fxFlex="70" [style]="isCalling ? 'display: none;' : ''">
    <div class="video-area">
      <video id="video-pharmacist"></video>
      <video id="video-patient"></video>
    </div>
    <chat class="chat-area" [posts]="posts" (sendMessageEvent)="sendMessage($event)"></chat>
  </div>
  <div fxFlex="70" [style]="isCalling ? '' : 'display: none;'">
    <div class="calling">
      <span>呼び出し中です....</span>
    </div>
  </div>
  <div fxFlex="30" class="template-area">
    <qa-template (modelEvent)="qaAnswer = $event"></qa-template>
  </div>
</div>
<div class="buttons-bar" fxLayout="row" fxLayoutAlign="space-between">
  <div>
    <button mat-mini-fab (click)="toggleMute()" aria-label="Mute toggle" [color]="muted ? 'warn' : 'primary'">
      <mat-icon class="muted">{{ muted ? 'mic_off' : 'mic' }}</mat-icon>
    </button>
    <button mat-mini-fab (click)="toggleCamera()" aria-label="Camera toggle" [color]="!useCamera ? 'warn' : 'primary'">
      <mat-icon class="video">{{ useCamera ? 'videocam' : 'videocam_off' }}</mat-icon>
    </button>
  </div>
  <div *ngIf="!isMeetingStarted">
    <button
      mat-raised-button
      *ngIf="isPerformed"
      (click)="incompleteMeeting()"
      aria-label="incomplete meeting"
      color="warn"
    >
      服薬指導の実施を中止する
    </button>
    <button mat-raised-button (click)="savePciQa()" aria-label="incomplete meeting" color="primary">
      服薬指導QAを保存する
    </button>
    <button mat-raised-button *ngIf="isConfirmed" (click)="startMeeting()" aria-label="start meeting" color="primary">
      開始する
    </button>
    <button mat-raised-button *ngIf="isPerformed" (click)="startMeeting()" color="primary">
      再接続する
    </button>
    <button *ngIf="isEndMeeting" mat-raised-button color="warn" fxFlexOffset="10px" (click)="complete()">
      服薬指導を終了
    </button>
  </div>
  <div *ngIf="isMeetingStarted">
    <button mat-raised-button (click)="savePciQa()" aria-label="incomplete meeting" color="primary">
      服薬指導QAを保存する
    </button>
    <button mat-raised-button *ngIf="isCancelled" (click)="cancel()" aria-label="cancel meeting" color="warn">
      呼び出しを終了する
    </button>
    <button
      mat-raised-button
      *ngIf="!isCancelled"
      (click)="endMeeting('pharmacist')"
      aria-label="end meeting"
      color="warn"
    >
      終了する
    </button>
  </div>
</div>
