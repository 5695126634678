import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPatientInfo, IPrescription } from 'src/models';
import { PrescriptionService } from 'src/services/api/prescription.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrescriptionStatus } from 'src/models/prescription';

@Component({
  selector: 'prescription-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class PrescriptionDetailComponent implements OnInit {
  prescription: IPrescription | null = null;
  images: string[] = [];
  patientInfo: IPatientInfo | null = null;
  loading = true;
  invalidButtonLoading = false;
  acceptButtonLoading = false;
  imagesForCarousel: { path: string }[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly prescriptionService: PrescriptionService,
    public readonly dialog: MatDialog,
  ) {}

  async ngOnInit() {
    await this.fetchPrescription();
    if (this.prescription !== null) {
      const latestUpload = this.prescription.uploads.sort((a, b) => b.updated_at - a.updated_at)[0];
      this.images = await Promise.all(
        latestUpload.images.map(i =>
          this.prescriptionService.getImage((this.prescription as IPrescription).id, latestUpload.id, i),
        ),
      ).then(result => result.filter(r => !!r).map(r => 'data:image/jpeg;base64,' + r));
    }
    this.imagesForCarousel = this.images.map(i => ({ path: i }));
  }

  get onNormalFlow() {
    return (
      this.prescription?.status !== PrescriptionStatus.req_resend &&
      this.prescription?.status !== PrescriptionStatus.rejected
    );
  }

  get patientInfoId(): string | null {
    return this.prescription?.patient_info_id ?? null;
  }

  get patientName() {
    return (
      (this.prescription?.patient_info?.family_name ?? '') + ' ' + (this.prescription?.patient_info?.given_name ?? '')
    );
  }

  get patientNameKana() {
    return (
      (this.prescription?.patient_info?.family_name_kana ?? '') +
      ' ' +
      (this.prescription?.patient_info?.given_name_kana ?? '')
    );
  }

  getAnswer(index: number) {
    if (!this.prescription?.mq_answers || !this.prescription.mq_answers[index].answers) {
      return '';
    }
    return this.prescription.mq_answers[index].answers
      .reduce((acc: string, cur: string) => acc + cur + ', ', '')
      .slice(0, -2);
  }

  private async fetchPrescription() {
    try {
      this.loading = true;
      this.prescription = await this.prescriptionService.find(this.route.snapshot.paramMap.get('prescriptionId') ?? '');
      if (!this.prescription) {
        console.log('prescription data not found');
        this.router.navigate(['pharmacist/prescriptions']);
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async openConfirmDialog() {
    const dialogRef = this.dialog.open(PrescriptionConfirmDialog, {
      data: { prescriptionId: this.prescription?.id },
      minWidth: 400,
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result.needsFetch) {
        this.loading = true;
        await new Promise<void>(resolve => setTimeout(() => resolve(), 3000));
        await this.fetchPrescription();
      }
    });
  }

  async openRequestResendDialog() {
    const dialogRef = this.dialog.open(PrescriptionRequestResendDialog, {
      data: { prescriptionId: this.prescription?.id },
      minWidth: 400,
      maxWidth: 500,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result.needsFetch) {
        this.loading = true;
        await new Promise<void>(resolve => setTimeout(() => resolve(), 3000));
        await this.fetchPrescription();
      }
    });
  }

  async openRejectDialog() {
    const dialogRef = this.dialog.open(PrescriptionRejectDialog, {
      data: { prescriptionId: this.prescription?.id },
      minWidth: 400,
      maxWidth: 500,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result.needsFetch) {
        this.loading = true;
        await new Promise<void>(resolve => setTimeout(() => resolve(), 3000));
        await this.fetchPrescription();
      }
    });
  }

  async print() {
    const target = document.querySelectorAll('app-header,.hamburger-menu,.root,footer');
    target.forEach(e => e.classList.add('hidden-on-print'));
    window.print();
    target.forEach(e => e.classList.remove('hidden-on-print'));
  }

  async confirm() {
    if (this.prescription === null) {
      return;
    }
    this.openConfirmDialog();
  }

  async dispensed() {
    if (this.prescription === null) {
      return;
    }
    if (!confirm('調剤の完了を患者に通知しますか？(この操作は取り消せません)')) {
      return;
    }
    try {
      this.loading = true;
      await Promise.all([
        this.prescriptionService.dispensed(this.prescription.id),
        new Promise<void>(resolve => setTimeout(() => resolve(), 3000)),
      ]);
      await this.fetchPrescription();
    } catch (error) {
      console.log(error);
      alert('処理に失敗しました。');
    } finally {
      this.loading = false;
    }
  }

  async complete() {
    if (this.prescription === null) {
      return;
    }
    if (!confirm('薬の処方を完了としますか？(この操作は取り消せません)')) {
      return;
    }
    try {
      this.loading = true;
      await Promise.all([
        this.prescriptionService.complete(this.prescription.id),
        new Promise<void>(resolve => setTimeout(() => resolve(), 3000)),
      ]);
      await this.fetchPrescription();
    } finally {
      this.loading = false;
    }
  }

  async requestResend() {
    if (this.prescription === null) {
      return;
    }
    this.openRequestResendDialog();
  }

  async reject() {
    if (this.prescription === null) {
      return;
    }
    this.openRejectDialog();
  }
}

@Component({
  selector: 'prescription-confirm-dialog',
  templateUrl: './confirm-dialog.html',
  styleUrls: ['./detail.component.scss'],
})
export class PrescriptionConfirmDialog {
  prescriptionId: string;
  message = '';
  constructor(
    public dialogRef: MatDialogRef<PrescriptionRejectDialog>,
    @Inject(MAT_DIALOG_DATA) data: { prescriptionId: string },
    private prescriptionService: PrescriptionService,
  ) {
    this.prescriptionId = data.prescriptionId;
  }
  async confirm() {
    if (!confirm('この処方箋を受領します。よろしいですか？(この操作は取り消せません)')) {
      return;
    }
    try {
      await this.prescriptionService.confirm(this.prescriptionId, this.message);
      this.dialogRef.close({ needsFetch: true });
    } catch (error) {
      console.log(error);
      alert('処理に失敗しました。');
    }
  }
}

@Component({
  selector: 'prescription-reject-dialog',
  templateUrl: './reject-dialog.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrescriptionRejectDialog {
  prescriptionId: string;
  message = '';
  statusReason: 0 | 1 | 2 | 3 = 3;
  readonly rejectMessages = [
    '処方箋の有効期限が切れているため受け付けることができません。',
    '処方箋に記載された患者名と選択された患者名が異なるため受け付けることができません。',
    '送信された画像が処方箋ではないため受け付けることができません。',
    'その他',
  ];
  constructor(
    public dialogRef: MatDialogRef<PrescriptionRejectDialog>,
    @Inject(MAT_DIALOG_DATA) data: { prescriptionId: string },
    private prescriptionService: PrescriptionService,
  ) {
    this.prescriptionId = data.prescriptionId;
  }
  async reject() {
    if (!confirm('処方箋の受け取りを拒否しますか？(この操作は取り消せません)')) {
      return;
    }
    try {
      await this.prescriptionService.reject(this.prescriptionId, this.message, this.rejectMessages[this.statusReason]);
      this.dialogRef.close({ needsFetch: true });
    } catch (error) {
      console.log(error);
      alert('処理に失敗しました。');
    }
  }
}

@Component({
  selector: 'prescription-req-resend-dialog',
  templateUrl: './req-resend-dialog.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrescriptionRequestResendDialog {
  prescriptionId: string;
  message = '';
  statusReason: 0 | 1 | 2 | 3 | 4 = 4;
  readonly requestResendMessages = [
    '撮影された処方箋に不足があります。処方箋のすべてのページをお送りください。',
    '処方箋の全体が映っていません。処方箋全体が入るように撮影してお送りください。',
    'ピントが合っていないため処方箋画像が読み取れません。再度撮影してからお送りください。',
    '画像が暗くて処方箋画像が読み取れません。再度撮影してからお送りください。',
    'その他',
  ];
  constructor(
    public dialogRef: MatDialogRef<PrescriptionRejectDialog>,
    @Inject(MAT_DIALOG_DATA) data: { prescriptionId: string },
    private prescriptionService: PrescriptionService,
  ) {
    this.prescriptionId = data.prescriptionId;
  }
  async requestResend() {
    if (!confirm('この内容で再送要求を行いますか？(この操作は取り消せません)')) {
      return;
    }
    try {
      await this.prescriptionService.requestResend(
        this.prescriptionId,
        this.message,
        this.requestResendMessages[this.statusReason],
      );
      this.dialogRef.close({ needsFetch: true });
    } catch (error) {
      console.log(error);
      alert('処理に失敗しました。');
    }
  }
}
