<div
  class="hamburger-menu"
  (click)="sidenav.toggle()"
  *ngIf="this.type !== undefined && !sidenav.opened"
  fxLayout="column"
>
  <mat-icon>{{ sidenav.opened ? 'arrow-back' : 'menu' }}</mat-icon>
</div>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav
    #sidenav
    class="sidenav hidden-on-print"
    [mode]="(isSmallScreen$ | async) ? 'over' : 'side'"
    [opened]="!(isSmallScreen$ | async) && this.type !== undefined"
  >
    <app-sidenav (sidenavClose)="closeSidenav(sidenav)" (sidenavCloseForce)="sidenav.close()"></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <section class="container">
      <app-header [title]="title"></app-header>
      <main>
        <loading-spinner [loading]="loading$ | async"></loading-spinner>
        <router-outlet *ngIf="!(loading$ | async)"></router-outlet>
      </main>
      <app-footer></app-footer>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
