import { IEntityBase } from './entity-base';
import { IPatientInfo } from './patient-info';
import { IPharmacy } from './pharmacy';
import { IQAT, QuestionType } from './qa-template';

export interface IPrescription extends IEntityBase {
  id: string;
  patient_account_id: string;
  patient_info_id: string;
  patient_info?: IPatientInfo;
  pharmacy_id: string;
  pharmacy?: IPharmacy;
  status: PrescriptionStatus;
  mq_questions?: { type: QuestionType; question: string; answers: string[]; required: boolean }[];
  mq_answers?: { type: QuestionType; question: string; answers: string[] }[];
  uploads: { id: string; images: string[]; created_at: number; updated_at: number }[];
  created_at: number;
  updated_at: number;
  confirmed_at?: number;
  rejected_at?: number;
  req_resend_at?: number;
  dispensed_at?: number;
  completed_at?: number;
}

export enum PrescriptionStatus {
  create_new = 'create_new',
  sent = 'sent',
  confirmed = 'confirmed',
  dispensed = 'dispensed',
  req_resend = 'req_resend',
  rejected = 'rejected',
  completed = 'completed',
}
