<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading" fxLayout.gt-md="row" fxLayout="column" fxLayoutGap="30px">
  <div fxFlex.gt-md="50">
    <div class="title">服薬フォローテンプレートを利用</div>
    <mat-accordion class="list">
      <mat-card class="template-header list-header" fxLayout="row">
        <div fxFlex="75">テンプレート名</div>
        <div fxFlex="25">質問数</div>
      </mat-card>
      <mat-expansion-panel *ngFor="let template of pcfTemplates" hideToggle>
        <mat-expansion-panel-header fxLayout="row">
          <div fxFlex="75">{{ template.name }}</div>
          <div fxFlex="25" class="number-of-questions">{{ template.qa ? template.qa.length : 0 }}</div>
        </mat-expansion-panel-header>
        <div class="question-container" fxLayout="column" fxLayoutGap="10px">
          <div class="question-content" *ngFor="let question of template.qa" fxLayout="column" fxLayoutGap="5px">
            <mat-chip-list class="chip-list">
              <mat-chip *ngIf="question.required" class="required chip">必須</mat-chip>
              <mat-chip *ngIf="question.type === 'radio'" class="chip">1つ選択</mat-chip>
              <mat-chip *ngIf="question.type === 'checkbox'" class="chip">複数選択</mat-chip>
              <mat-chip *ngIf="question.type === 'textbox'" class="chip">自由記述</mat-chip>
            </mat-chip-list>
            <mat-divider class="divider"></mat-divider>
            <div>{{ question.question ? question.question : '(質問文なし)' }}</div>
            <div fxLayout="row-wrap" fxLayoutGap="5px">
              <div *ngFor="let choice of question.answers">・{{ choice ? choice : '(選択肢名なし)' }}</div>
            </div>
          </div>
        </div>
        <mat-action-row>
          <button mat-button color="primary" routerLink="../new" [queryParams]="{ templateId: template.id }">
            ベースとして選択
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxFlex.gt-md="50">
    <div class="title">過去の服薬フォローを利用</div>
    <mat-accordion class="past-follow-list list">
      <mat-card class="past-follow-header list-header" fxLayout="row">
        <div fxFlex="40">送信日時</div>
        <div fxFlex="40">患者氏名</div>
        <div fxFlex="20">質問数</div>
      </mat-card>
      <mat-expansion-panel *ngFor="let follow of follows" hideToggle>
        <mat-expansion-panel-header fxLayout="row">
          <div fxFlex="40">
            {{
              follow.send_at !== null && follow.send_at !== undefined
                ? (follow.send_at | date: 'yyyy/MM/dd HH:mm:ss')
                : ''
            }}
          </div>
          <div fxFlex="40">{{ follow.patient_name }}</div>
          <div fxFlex="20" class="number-of-questions">
            {{ follow.pcf_questions ? follow.pcf_questions.length : 0 }}
          </div>
        </mat-expansion-panel-header>
        <div class="question-container" fxLayout="column" fxLayoutGap="10px">
          <div
            class="question-content"
            *ngFor="let question of follow.pcf_questions"
            fxLayout="column"
            fxLayoutGap="5px"
          >
            <mat-chip-list class="chip-list">
              <mat-chip *ngIf="question.required" class="required chip">必須</mat-chip>
              <mat-chip *ngIf="question.type === 'radio'" class="chip">1つ選択</mat-chip>
              <mat-chip *ngIf="question.type === 'checkbox'" class="chip">複数選択</mat-chip>
              <mat-chip *ngIf="question.type === 'textbox'" class="chip">自由記述</mat-chip>
            </mat-chip-list>
            <mat-divider class="divider"></mat-divider>
            <div>{{ question.question ? question.question : '(質問文なし)' }}</div>
            <div fxLayout="row-wrap" fxLayoutGap="5px">
              <div *ngFor="let choice of question.answers">・{{ choice ? choice : '(選択肢名なし)' }}</div>
            </div>
          </div>
        </div>
        <mat-action-row>
          <button mat-button color="primary" routerLink="../new" [queryParams]="{ followId: follow.id }">
            ベースとして選択
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
