import { Component, OnInit } from '@angular/core';
import { NewArrival, NewArrivalService } from 'src/services/new-arrival.service';

@Component({
  selector: 'pharmacist-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  loading = true;
  readonly displayedColumns = ['textTime', 'patientName', 'title'];
  notices: NewArrival[] = [];

  constructor(private readonly newArrivalService: NewArrivalService) {}

  async ngOnInit() {
    this.loading = true;
    try {
      this.notices = await this.newArrivalService.getNewArrivals();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
}
