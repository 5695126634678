<loading-spinner [loading]="loading"></loading-spinner>
<div class="root" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row-reverse">
    <button mat-raised-button color="primary" routerLink="base-select">新規服薬フォロー</button>
  </div>
  <div class="table" fxLayout="column">
    <mat-card class="header" fxLayout="row" fxFlexGap="10px">
      <div
        class="clickable"
        fxFlex="{{ columnProportion[0] }}"
        fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
        fxLayout="row"
        (click)="sortChange(0)"
      >
        <div>送信日時</div>
        <mat-icon fxFlexAlign="center" *ngIf="sortConfig[0] === 1">arrow_upward</mat-icon>
        <mat-icon fxFlexAlign="center" *ngIf="sortConfig[0] === -1">arrow_downward</mat-icon>
      </div>
      <div fxFlex="{{ columnProportion[1] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">患者名</div>
      <div fxFlex="{{ columnProportion[2] }}" fxHide.lt-md>電話番号</div>
      <div
        class="clickable"
        fxFlex="{{ columnProportion[3] }}"
        fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
        fxLayout="row"
        #stateFilterControllerOrigin="cdkOverlayOrigin"
        cdk-overlay-origin
        (click)="showController(stateFilterControllerOrigin.elementRef)"
      >
        <div>状況</div>
        <mat-icon fxFlexAlign="center">filter_alt</mat-icon>
      </div>
      <div
        class="clickable"
        fxFlex="{{ columnProportion[4] }}"
        fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
        fxLayout="row"
        (click)="sortChange(1)"
      >
        <div>回答日時</div>
        <mat-icon fxFlexAlign="center" *ngIf="sortConfig[1] === 1">arrow_upward</mat-icon>
        <mat-icon fxFlexAlign="center" *ngIf="sortConfig[1] === -1">arrow_downward</mat-icon>
      </div>
    </mat-card>
    <mat-card *ngFor="let follow of filteredFollows" class="element clickable" (click)="openDialog(follow)">
      <div fxFlex="{{ columnProportion[0] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
        {{ follow.send_at | date: timeDisplayFormat }}
      </div>
      <div fxFlex="{{ columnProportion[1] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
        {{ follow.patient_name }}
      </div>
      <div fxFlex="{{ columnProportion[2] }}" fxHide.lt-md>{{ follow.tel }}</div>
      <div fxFlex="{{ columnProportion[3] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">{{ follow.status }}</div>
      <div fxFlex="{{ columnProportion[4] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
        {{ follow.answered_at | date: timeDisplayFormat }}
      </div>
    </mat-card>
  </div>
</div>
