import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IPCITemplate } from 'src/models';
import { Pci } from 'src/models/pci';
import { PCITemplateService } from 'src/services/api/pci-template.service';
import { PciService } from 'src/services/api/pci.service';

@Component({
  selector: 'app-pci-template-list',
  templateUrl: './pci-template-list.component.html',
  styleUrls: ['./pci-template-list.component.scss'],
})
export class PciTemplateListComponent implements OnInit {
  pciTemplates: IPCITemplate[] = [];
  displayedColumns: string[] = ['name'];
  loading = true;

  constructor(
    private pciTemplateService: PCITemplateService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.pciTemplates = await this.fetchPciTemplates();
  }

  private async fetchPciTemplates(): Promise<IPCITemplate[]> {
    try {
      this.loading = true;
      return await this.pciTemplateService.findAll();
    } finally {
      this.loading = false;
    }
  }

  openDetailDialog(pciTemplate: IPCITemplate) {
    const pciId = this.route.snapshot.paramMap.get('pciId');
    const dialogRef = this.dialog.open(PciTemplateDetailDialog, {
      data: { pciTemplate, pciId },
      minWidth: 400,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'pci-detail-dialog',
  templateUrl: './pci-detail-dialog.component.html',
  styleUrls: ['./pci-template-list.component.scss'],
})
export class PciTemplateDetailDialog implements OnInit {
  pciTemplate: IPCITemplate;
  pciId: string;
  pci?: Pci;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<PciTemplateDetailDialog>,
    @Inject(MAT_DIALOG_DATA) data: { pciTemplate: IPCITemplate; pciId: string },
    private pciService: PciService,
    private router: Router,
  ) {
    this.pciTemplate = data.pciTemplate;
    this.pciId = data.pciId;
  }

  async ngOnInit() {
    const pci = await this.pciService.find(this.pciId);
    this.pci = pci ? new Pci(pci) : undefined;
  }

  public async specifyPciTemplate() {
    const message = `服薬指導テンプレートを指定します。よろしいですか？`;
    if (confirm(message)) {
      try {
        this.loading = true;
        if (!this.pci) {
          throw new Error('pciが取得できませんでした。');
        }
        await this.pciService.specifyPciTemplate(this.pci, this.pciTemplate);
      } catch (error) {
        alert('服薬指導テンプレートの指定に失敗しました。');
        throw error;
      } finally {
        this.loading = false;
        this.dialogRef.close();
      }
    }
    this.router.navigate([`/pharmacist/reservation/${this.pci?.id}`]);
  }
}
