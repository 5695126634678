import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { ReservationForList } from 'src/models/pci';
import { displayQa, TemplateQa } from 'src/models/pci-template';
import { QuestionType } from 'src/models/qa-template';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';

@Component({
  selector: 'qa-template',
  templateUrl: './qa-template.component.html',
  styleUrls: ['./qa-template.component.scss'],
})
export class QaTemplateComponent implements OnInit {
  @Output() modelEvent = new EventEmitter();
  qaAnswer: displayQa[] = [];
  displayQa: displayQa[] = [];
  pharmacistId = '';

  constructor(
    private pciService: PciService,
    private route: ActivatedRoute,
    private pharmacistsService: PharmacistService,
    private store: Store,
  ) {}

  async ngOnInit() {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacist),
        filter(isNotNull),
        take(1),
      )
      .subscribe(pharmacist => {
        this.pharmacistId = pharmacist?.id;
      });
    const pci = await this.pciService.find(this.route.snapshot.paramMap.get('pciId') ?? '');
    const pharmacists = await this.pharmacistsService.find(`${this.pharmacistId}`);

    if (pci === null) {
      throw new Error('fetch pci failed.');
    }

    const reservationForList = new ReservationForList(pci, pharmacists);

    const qaTemplate = reservationForList.pci_questions;
    const qaAnswer = reservationForList.pci_answers;

    if (!qaTemplate) {
      throw new Error('qaテンプレートが取得できないため、表示できませんでした。');
    }

    this.displayQa = new TemplateQa(qaTemplate, 'template').displayQa;
    this.qaAnswer = new TemplateQa(qaTemplate, qaAnswer ? 'answered' : 'newAnswer', qaAnswer).displayQa;
  }

  inputText() {}

  changeValue() {
    this.qaAnswer
      .filter(p => p.type === QuestionType.checkbox)
      .forEach(p => {
        p.answers = p.checkBox.filter(c => c.checked).map(r => r.label);
      });
    this.modelEvent.emit(this.qaAnswer);
  }
}
