import { IEntityBase } from './entity-base';

export interface IPharmacyPatientInfo extends IEntityBase {
  id: string;
  activated_at: number;
  deactivated_at: number | null;
  patient_info: IPatientInfo;
  pharmacy_id: string;
}

export interface IPatientInfo extends IEntityBase {
  id: string;
  patient_account_id?: string;
  given_name: string;
  given_name_kana: string;
  family_name: string;
  family_name_kana: string;
  birth_date: number;
  sex: PatientSex;
  zip?: string;
  prefecture?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  tel?: string;
  email?: string;
  relationship?: RelationShips;
}

export enum PatientSex {
  male = 'male',
  female = 'female',
  other = 'other',
}
export enum RelationShips {
  oneself = 'oneself',
  children = 'children',
  parents = 'parents',
  relatives = 'relatives',
  others = 'others',
}
