import { Component, OnInit } from '@angular/core';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { IPharmacyPatientInfo } from 'src/models/patient-info';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  pharmacyPatientInfos: IPharmacyPatientInfo[] = [];
  patientsDisabled: boolean[] = [];
  readonly displayedColumns: readonly string[] = ['name', 'birth_date', 'sex'];

  nameFilterFormControl = new FormControl('');
  loading = true;

  readonly pageSize = 20;
  totalRecords = 0;
  pageNumber = 0;
  lastKeys: string[] = [];

  constructor(public dialog: MatDialog, private patientInfoService: PatientInfoService) {
    this.nameFilterFormControl.valueChanges.subscribe(_ => this.filterByName());
  }
  async ngOnInit() {
    this.loading = true;
    try {
      await this.fetchPatientInfo();
      this.filterByName();
    } finally {
      this.loading = false;
    }
  }

  async fetchPatientInfo(params?: { last_key?: string }) {
    const pagination = await this.patientInfoService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(result => {
        this.pharmacyPatientInfos = result.patient_infos;
        return result.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
  }

  private filterByName() {
    const filters = (this.nameFilterFormControl.value as string).trim().split(' ');
    this.patientsDisabled = this.pharmacyPatientInfos.map(
      p =>
        !(
          (filters.length === 1 && !filters[0]) ||
          filters.every(f => this.getPatientName(p).includes(f)) ||
          filters.every(f => this.getPatientNameKana(p).includes(f))
        ),
    );
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchPatientInfo({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }

  getPatientName(info: IPharmacyPatientInfo) {
    return info.patient_info.family_name + info.patient_info.given_name;
  }

  getPatientNameKana(info: IPharmacyPatientInfo) {
    return info.patient_info.family_name_kana + info.patient_info.given_name_kana;
  }
}
