<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading">
  <ul class="patient-info" *ngIf="patientInfo">
    <li>
      <h5>姓</h5>
      <p>
        {{ patientInfo?.family_name ? patientInfo?.family_name : '' }}
        （{{ patientInfo?.family_name_kana ? patientInfo?.family_name_kana : '' }}）
      </p>
    </li>
    <li>
      <h5>名</h5>
      <p>
        {{ patientInfo?.given_name ? patientInfo?.given_name : '' }}
        （{{ patientInfo?.given_name_kana ? patientInfo?.given_name_kana : '' }}）
      </p>
    </li>
    <li>
      <h5>生年月日</h5>
      <p>{{ (patientInfo?.birth_date ? patientInfo?.birth_date : '') | date: 'yyyy/MM/dd' }}</p>
    </li>
    <li>
      <h5>性別</h5>
      <p>{{ patientInfo?.sex ? (patientInfo?.sex | patient_sex) : '' }}</p>
    </li>
    <li>
      <h5>郵便番号</h5>
      <p>{{ patientInfo?.zip ? patientInfo?.zip : '' }}</p>
    </li>
    <li>
      <h5>住所</h5>
      <p>
        {{ patientInfo?.prefecture ? patientInfo?.prefecture : '' }}
        {{ patientInfo?.address1 ? patientInfo?.address1 : '' }}
        {{ patientInfo?.address2 ? patientInfo?.address2 : '' }}
        {{ patientInfo?.address3 ? patientInfo?.address3 : '' }}
      </p>
    </li>
    <li>
      <h5>電話番号</h5>
      <p>{{ patientInfo?.tel ? patientInfo?.tel : '' }}</p>
    </li>
    <li>
      <h5>メールアドレス</h5>
      <p>{{ patientInfo?.email ? patientInfo?.email : '' }}</p>
    </li>
  </ul>

  <div>
    <h3>服薬指導履歴</h3>
    <div *ngFor="let reservation of reservations" class="history-detail">
      <mat-accordion>
        <mat-expansion-panel [disabled]="reservation.pci_answers === undefined || reservation.pci_answers.length === 0">
          <mat-expansion-panel-header>
            <mat-panel-title class="time">
              {{ (reservation.time_frames ? reservation.time_frames[0].start_time : '') | date: 'yyyy/MM/dd HH:mm' }}
              ~
              {{ (reservation.time_frames ? reservation.time_frames[0].end_time : '') | date: 'yyyy/MM/dd HH:mm' }}
              <mat-chip-list>
                <mat-chip disabled [class]="reservation.status">
                  {{ reservation.status | reservation_status }}
                </mat-chip>
              </mat-chip-list>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="qa" *ngFor="let qa of reservation.pci_answers ? reservation.pci_answers : []">
            <h5 class="question">{{ qa.question }}</h5>
            <p class="answer" *ngIf="qa.type === 'radio'">{{ qa.answers }}</p>
            <section class="list-section" *ngIf="qa.type === 'checkbox'">
              <li *ngFor="let ans of qa.answers">
                {{ ans }}
              </li>
            </section>
            <p class="answer" *ngIf="qa.type === 'textbox'">{{ qa.answers }}</p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
