import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CognitoService, configure } from 'src/services/cognito.service';
import { when } from '../modules/when';
import { IPharmacist, IPharmacy } from 'src/models';
import { isNotNull } from '../modules/nonNullPredicate';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { loadSessions } from '../app-store/actions/session.actions';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private cognito: CognitoService, private store: Store) {}

  private async getUserId(type: 'pharmacy' | 'pharmacist'): Promise<string | null> {
    try {
      configure(type);

      // Cognitoのセッションがあるか確認
      return this.cognito.isAuthenticated();
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private async getUserInfo(userId: string, type: 'pharmacy' | 'pharmacist'): Promise<IPharmacy | IPharmacist | null> {
    try {
      const token = await this.cognito.getAccessToken();
      const config = {
        headers: {
          Authorization: token.getJwtToken(),
        },
      };
      const baseUrl = environment.api_base_url;
      return await when(type)
        .on(
          v => v === 'pharmacy',
          async _ => (await axios.get(`${baseUrl}${type}/pharmacies/${userId}`, config)).data as IPharmacy,
        )
        .on(
          v => v === 'pharmacist',
          async _ => (await axios.get(`${baseUrl}${type}/pharmacists/${userId}`, config)).data as IPharmacist,
        )
        .otherwise(async _ => null);
    } catch (error) {
      return null;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const path = route.pathFromRoot
      .map(snapshot => {
        if (snapshot.url.length === 0) {
          return null;
        }
        return snapshot.url[0].path as string;
      })
      .filter(isNotNull);
    const type: 'pharmacy' | 'pharmacist' | '' = path.length === 0 ? '' : (path[0] as 'pharmacy' | 'pharmacist');
    if (type === '') {
      return false;
    }

    const userId = await this.getUserId(type);
    if (!userId) {
      this.cognito.logout();
      this.router.navigate([type ? `${type}/login` : '']);
      return false;
    }

    if (!(await this.getUserInfo(userId, type))) {
      // ユーザIDのみ存在する場合はログアウトせず薬局情報登録画面へ
      this.router.navigate(['pharmacy/registration'], {
        queryParams: { id: userId, name: (await Auth.currentUserInfo()).username },
      });
      return false;
    }

    // ユーザが取得できればOKとみなしGuard処理終了
    this.store.dispatch(loadSessions({ type }));
    return true;
  }
}
