import { Component, OnInit } from '@angular/core';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { IPCFTemplate } from 'src/models';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pcf-templates',
  templateUrl: './pcf-templates.component.html',
  styleUrls: ['./pcf-templates.component.scss'],
})
export class PcfTemplatesComponent implements OnInit {
  constructor(private pcfTemplateService: PCFTemplateService) {}
  pcfTemplates: IPCFTemplate[] = [];
  readonly displayedColumns: readonly string[] = ['name'];

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 20;
  private lastKeys: string[] = [];

  loading = true;

  async ngOnInit() {
    this.loading = true;
    try {
      await this.fetchPcfTemplates();
    } finally {
      this.loading = false;
    }
  }

  private async fetchPcfTemplates(params?: { last_key?: string }) {
    const pagination = await this.pcfTemplateService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(data => {
        this.pcfTemplates = data.pcf_templates;
        return data.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchPcfTemplates({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }
}
