import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { IPatientInfo, IPrescription } from 'src/models';
import { PrescriptionStatus } from 'src/models/prescription';
import { PrescriptionStatusPipe } from 'src/pipes/prescription_status.pipe';
import { PrescriptionService } from 'src/services/api/prescription.service';
import { PopupCheckboxService } from 'src/app/parts/checkbox-list/checkbox-list.component';

@Component({
  selector: 'prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss'],
})
export class PrescriptionsComponent implements OnInit, OnDestroy {
  @ViewChild('stateFilterControllerOrigin') stateFilterControllerOrigin!: CdkOverlayOrigin;
  patientFilterController = new FormControl('');
  readonly status = [
    PrescriptionStatus.sent,
    PrescriptionStatus.confirmed,
    PrescriptionStatus.dispensed,
    PrescriptionStatus.req_resend,
    PrescriptionStatus.rejected,
    PrescriptionStatus.completed,
  ];
  statusFilter = [true, true, true, true, false, false];
  prescriptions: IPrescription[] = [];
  prescriptionsDisabled: boolean[] = [];
  loading = true;

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 20;
  private lastKeys: string[] = [];

  patientFilterControllerSubscription: Subscription;

  private get statusFilterString() {
    return this.statusFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.status[index] + ',' : acc), '')
      .slice(0, -1);
  }

  constructor(
    private readonly prescriptionService: PrescriptionService,
    private readonly prescriptionStatusPipe: PrescriptionStatusPipe,
    private readonly popupCheckboxService: PopupCheckboxService,
  ) {
    this.patientFilterControllerSubscription = this.patientFilterController.valueChanges
      .pipe<string>(startWith(''))
      .subscribe(_ => this.filterByName());
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.fetchPrescriptions({ status: this.statusFilterString });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.patientFilterControllerSubscription.unsubscribe();
  }

  private async fetchPrescriptions(params?: { status?: string; last_key?: string }) {
    const pagination = await this.prescriptionService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(data => {
        this.prescriptions = data.prescriptions;
        return data.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
    this.filterByName();
  }

  async filterByStatus() {
    this.statusFilter = await this.popupCheckboxService.showOverlay(
      this.stateFilterControllerOrigin.elementRef,
      this.status.map(s => this.prescriptionStatusPipe.transform(s)),
      this.statusFilter,
    );
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      await this.fetchPrescriptions({ status: this.statusFilterString });
    } finally {
      this.loading = false;
    }
  }

  private filterByName() {
    const filters = (this.patientFilterController.value as string).trim().split(' ');
    this.prescriptionsDisabled = this.prescriptions.map(
      p =>
        !(
          (filters.length === 1 && !filters[0]) ||
          filters.every(f => this.getPatientName(p.patient_info).includes(f)) ||
          filters.every(f => this.getPatientNameKana(p.patient_info).includes(f))
        ),
    );
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchPrescriptions({
        status: this.statusFilterString,
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  getPatientNameKana(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name_kana ?? '') + (patientInfo?.given_name_kana ?? '');
  }
}
