<div class="container-round" fxLayout="column">
  <div
    class="title"
    [routerLink]="type === 'pharmacist' ? '/pharmacist/index' : type === 'pharmacy' ? '/pharmacy/index' : '/index'"
  >
    Connect Online
  </div>
  <div class="close-sidenav" fxLayout="column" (click)="closeSidenavForce()">
    <mat-icon>arrow_back</mat-icon>
  </div>
  <div
    *ngFor="let item of filteredItems; index as i"
    class="link-button"
    [class]="item.current ? 'current' : ''"
    routerLink="{{ item.href }}"
    fxLayout="row"
    fxLayoutAlign="space-around"
    (click)="closeSidenav()"
  >
    <mat-icon>{{ item.icon ? item.icon : 'home' }}</mat-icon>
    <div class="{{ item.title[1] ? 'double-row' : 'single-row' }} label">
      <div fxLayout="row" fxLayoutAlign="space-around">
        <div *ngFor="let letter of item.title[0].split('')">{{ letter }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-around" *ngIf="item.title[1]">
        <div *ngFor="let letter of item.title[1].split('')">{{ letter }}</div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
