<loading-spinner [loading]="loading"></loading-spinner>
<div class="root" *ngIf="!loading" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row-reverse">
    <button mat-raised-button color="primary" (click)="save()">登録</button>
  </div>
  <div class="head-part" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column" fxLayoutGap.xs="10px">
    <div [style.borderColor]="noPatientAlert ? '#f44336' : ''" class="select-patient" fxFlex="50">
      <div class="large-font" fxLayout="row" fxLayoutGap="10px">
        <mat-icon fxFlexAlign="center">person_outline</mat-icon>
        <div>患者設定</div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div *ngIf="!selectedPatient">患者を下のリストから選択</div>
      <div *ngIf="selectedPatient">
        <div>患者名 : {{ patientNameWithSpace(selectedPatient) }}</div>
        <div>生年月日 : {{ selectedPatient.birth_date | date: 'yyyy/MM/dd' }}</div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <mat-form-field class="form-field patient-name">
        <mat-label>患者検索</mat-label>
        <input
          matInput
          type="text"
          placeholder="患者名を入力して選択"
          [matAutocomplete]="patientAutoFill"
          [formControl]="patientName"
        />
        <mat-autocomplete #patientAutoFill="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredPatients | async"
            (click)="selectedPatient = option; noPatientAlert = false"
            [value]="patientNameWithSpace(option)"
            >{{ patientNameWithSpace(option) }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="select-time" fxFlex="50" [style.borderColor]="noDateAlert ? '#f44336' : ''">
      <div class="large-font" fxLayout="row" fxLayoutGap="10px">
        <mat-icon fxFlexAlign="center">access_alarm</mat-icon>
        <div>送信時刻設定</div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field class="form-field date">
          <mat-label>日付を選択</mat-label>
          <input matInput [matDatepicker]="picker" (dateInput)="selectedDate = $event.value; noDateAlert = false" />
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <button mat-stroked-button (click)="picker.open()"><mat-icon>event</mat-icon></button>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field class="form-field time">
          <mat-select [(ngModel)]="selectedHour">
            <mat-option *ngFor="let hour of selectableHour" [value]="hour">{{ hour }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div fxFlexAlign="center">時</div>
        <mat-form-field class="form-field time">
          <mat-select [(ngModel)]="selectedMin">
            <mat-option *ngFor="let minute of selectableMin" [value]="minute">{{
              minute.toString().padStart(2, '0')
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div fxFlexAlign="center">分</div>
      </div>
    </div>
  </div>
  <div class="follow-edit-part">
    <div
      class="question-container"
      cdkDropList
      #questionList
      (cdkDropListDropped)="dropQuestion($event)"
      fxLayout="column"
      fxLayoutGap="15px"
      fxFlexAlign="center"
    >
      <mat-card class="question-content" cdkDrag *ngFor="let question of questions; index as i" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-icon fxFlexAlign="stretch" cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
          <div fxLayout="row" fxLayoutGap="10px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="space-evenly" class="type-and-require-select">
              <mat-checkbox [(ngModel)]="question.required">必須</mat-checkbox>
              <mat-radio-group class="type-toggle" fxLayout="column" [(ngModel)]="question.type">
                <mat-radio-button [value]="'radio'">1つ選択</mat-radio-button>
                <mat-radio-button [value]="'checkbox'">複数選択</mat-radio-button>
                <mat-radio-button [value]="'textbox'">自由記述</mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px">
              <mat-form-field
                class="form-field question"
                fxFlex.gt-sm="45"
                fxFlexAlign.gt-sm="stretch"
                fxLayout="column"
                fxLayoutAlign="center"
              >
                <mat-label>質問文を入力</mat-label>
                <textarea class="textarea question" matInput [(ngModel)]="question.question"></textarea>
              </mat-form-field>
              <div class="choice-area" fxFlex.gt-sm="55">
                <div class="choice-container" cdkDropList (cdkDropListDropped)="dropChoice(i, $event)">
                  <mat-card
                    class="choice-content"
                    fxLayout="row"
                    fxLayoutGap="10px"
                    cdkDrag
                    *ngFor="let choice of question.answers; index as j; trackBy: trackByIndex"
                  >
                    <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center" class="choice-number-wrap">
                      <span class="choice-number">{{ j + 1 }}.</span>
                    </div>
                    <mat-form-field fxFlex>
                      <mat-label>選択肢名を入力</mat-label>
                      <input matInput type="text" [(ngModel)]="question.answers[j]" />
                    </mat-form-field>
                    <button
                      mat-icon-button
                      fxFlexAlign="center"
                      [disabled]="question.answers.length < 2"
                      (click)="question.answers.splice(j, 1)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-card>
                </div>
                <button mat-stroked-button (click)="question.answers.push('')" class="add-choice">
                  <div fxLayout="row" fxLayoutGap="10px">
                    <mat-icon fxFlexAlign="center">add_circle_outline</mat-icon>
                    <div fxFlexAlign="center">選択肢を追加</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <button mat-icon-button fxFlexAlign="start" (click)="questions.splice(i, 1)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
      <div *ngIf="questions.length === 0">質問がありません</div>
    </div>
  </div>
  <button mat-stroked-button fxFlexAlign="center" (click)="addQuestion()" class="add-question">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-icon fxFlexAlign="center">add_circle_outline</mat-icon>
      <div fxFlexAlign="center">質問を追加</div>
    </div>
  </button>
</div>
