<div class="root" fxLayout="column">
  <div class="top-area" fxLayout="row-reverse">
    <loading-button [name]="'新規作成'" [color]="'primary'" [loading]="loading" routerLink="./detail"></loading-button>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <mat-paginator
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
      ></mat-paginator>
      <div class="table" fxLayout="column">
        <mat-card class="header" fxLayout="row">
          <div fxFlex="75">名前</div>
          <div fxFlex="25">優先度</div>
        </mat-card>
        <mat-card *ngFor="let slot of reservationSlots" class="element" fxLayout="row" (click)="toDetailPage(slot.id)">
          <div fxFlex="75">{{ slot.name }}</div>
          <div fxFlex="25">{{ slot.priority }}</div>
        </mat-card>
      </div>
    </div>
    <div class="loading-shade" *ngIf="loading"><mat-spinner></mat-spinner></div>
  </div>
</div>
