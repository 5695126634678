<div class="root">
  <div fxLayout="column" fxLayoutGap="20px">
    <div
      *ngIf="!isActive"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="25px"
      class="active-warn-box inactive"
    >
      <div fxLayout="column">
        <div>この薬局は公開されていません。</div>
        <div class="no-mq-template-message" *ngIf="noMqTemplate">
          問診票テンプレートが登録されていないため、薬局を公開することができません。
        </div>
      </div>
      <loading-button
        color="accent"
        [name]="'薬局を公開する'"
        [loading]="loading"
        [disabled]="!areAllFormsValid || noMqTemplate"
        (click)="activatePharmacy()"
      ></loading-button>
    </div>
    <div
      *ngIf="isActive"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="25px"
      class="active-warn-box active"
    >
      <div fxLayout="column">
        <div>この薬局は公開されています。</div>
      </div>
      <loading-button [name]="'薬局を無効化する'" [loading]="loading" (click)="deactivatePharmacy()"></loading-button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field class="form-field">
        <mat-label>薬局コード</mat-label>
        <input matInput type="text" [value]="pharmacyCode" readonly />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>会社名</mat-label>
        <input matInput type="text" [formControl]="companyNameFormControl" required />
        <mat-error *ngIf="companyNameFormControl.hasError('required')">会社名は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>店舗名</mat-label>
        <input matInput type="text" [formControl]="storeNameFormControl" required />
        <mat-error *ngIf="storeNameFormControl.hasError('required')">店舗名は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="space-between" class="zip-code-wrap">
        <mat-form-field class="form-field small">
          <mat-label>郵便番号 (ハイフン不要)</mat-label>
          <input matInput type="text" [formControl]="zipFormControl" required />
          <mat-error *ngIf="zipFormControl.hasError('required')">郵便番号は<strong>必須</strong>です</mat-error>
          <mat-error *ngIf="zipFormControl.hasError('pattern') && !zipFormControl.hasError('required')">
            郵便番号の形式が正しくありません
          </mat-error>
        </mat-form-field>
        <button
          fxFlexAlign="center"
          mat-stroked-button
          [disabled]="zipFormControl.hasError('pattern') || zipFormControl.hasError('required') || fetchingAddress"
          (click)="getAddress()"
        >
          住所検索
        </button>
      </div>

      <mat-form-field class="form-field">
        <mat-label>都道府県</mat-label>
        <input matInput type="text" [formControl]="prefectureFormControl" [matAutocomplete]="autoPrefecture" required />
        <mat-autocomplete #autoPrefecture="matAutocomplete">
          <mat-option *ngFor="let prefecture of filteredPrefectures | async" [value]="prefecture">
            {{ prefecture }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="prefectureFormControl.hasError('required')">都道府県は<strong>必須</strong>です</mat-error>
        <mat-error *ngIf="prefectureFormControl.hasError('pattern') && !prefectureFormControl.hasError('required')">
          都道府県を入力してください
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>住所1</mat-label>
        <input matInput type="text" [formControl]="address1FormControl" required />
        <mat-error *ngIf="address1FormControl.hasError('required')">住所1は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>住所2</mat-label>
        <input matInput type="text" [formControl]="address2FormControl" required />
        <mat-error *ngIf="address2FormControl.hasError('required')">住所2は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>住所3</mat-label>
        <input matInput type="text" [formControl]="address3FormControl" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>電話番号 (ハイフン不要)</mat-label>
        <input matInput type="tel" [formControl]="telFormControl" required />
        <mat-error *ngIf="telFormControl.hasError('required')">電話番号は<strong>必須</strong>です</mat-error>
        <mat-error *ngIf="telFormControl.hasError('pattern') && !telFormControl.hasError('required')">
          電話番号の形式が正しくありません
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>Eメールアドレス</mat-label>
        <input matInput type="email" [formControl]="emailFormControl" required />
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          メールアドレスの形式が正しくありません
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">メールアドレスは<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>ホームページアドレス</mat-label>
        <input matInput type="text" [formControl]="webFormControl" required />
        <mat-error *ngIf="webFormControl.hasError('required')">
          ホームページアドレスは<strong>必須</strong>です
        </mat-error>
      </mat-form-field>
      <div>
        <div>位置情報</div>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field class="form-field small">
            <mat-label>緯度</mat-label>
            <input matInput type="text" [formControl]="latitudeFormControl" required />
            <mat-error *ngIf="latitudeFormControl.hasError('required')">緯度は<strong>必須</strong>です</mat-error>
            <mat-error *ngIf="latitudeFormControl.hasError('pattern') && !latitudeFormControl.hasError('required')">
              緯度の形式が正しくありません
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field small">
            <mat-label>経度</mat-label>
            <input matInput type="text" [formControl]="longitudeFormControl" required />
            <mat-error *ngIf="longitudeFormControl.hasError('required')">経度は<strong>必須</strong>です</mat-error>
            <mat-error *ngIf="longitudeFormControl.hasError('pattern') && !longitudeFormControl.hasError('required')">
              経度の形式が正しくありません
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <loading-button
          [name]="'変更を保存'"
          color="primary"
          (click)="save()"
          [disabled]="!areAllFormsValid"
        ></loading-button>
      </div>
    </div>
  </div>
</div>
