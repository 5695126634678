import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AxiosRequestConfig } from 'axios';
import { filter, map, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { ReservationForList } from 'src/models/pci';
import { IQAT } from 'src/models/qa-template';
import { Session } from 'src/models/session';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';
import { CognitoService } from 'src/services/cognito.service';
import * as fromSession from '../../app-store/reducers';
@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss'],
})
export class ReservationDetailComponent implements OnInit {
  pci!: ReservationForList;
  pharmacistId = '';
  displayedColumns: string[] = ['timeText', 'patientName', 'pharmacistName', 'wasConfirmed'];

  name = '';
  session?: Session;

  loading = false;

  qaAnswer: IQAT[] = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private pciService: PciService,
    private cognito: CognitoService,
    private pharmacistsService: PharmacistService,
  ) {}

  async ngOnInit() {
    try {
      this.loading = true;
      this.store.select<Session>(fromSession.getSession as any).subscribe(s => (this.session = s));
      getLoginSession(this.store)
        .pipe(
          map(s => s.pharmacist),
          filter(isNotNull),
          take(1),
        )
        .subscribe(pharmacist => {
          this.pharmacistId = pharmacist?.id;
        });
      this.pci = await this.fetchPci();
      console.log(this.pci);
    } finally {
      this.loading = false;
    }
  }

  async fetchPci() {
    const data = await this.pciService.find(this.route.snapshot.paramMap.get('pciId') ?? '');
    const pharmacists = await this.pharmacistsService.find(`${this.pharmacistId}`);
    if (data === null) {
      throw new Error('fetch pci failed');
    }
    return new ReservationForList(data, pharmacists);
  }

  async cancel() {
    if (!confirm(`患者名:${this.pci.patientName} の服薬指導をキャンセルします。よろしいですか？`)) {
      return;
    }
    try {
      this.loading = true;
      const config: AxiosRequestConfig = {};
      if (!config.headers) {
        config.headers = {};
      }
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.cancel(this.pci.id, config);
      this.router.navigate(['/pharmacist/reservation']);
    } catch (error) {
      alert('予約のキャンセルに失敗しました');
    } finally {
      this.loading = false;
    }
  }

  implementation() {
    this.router.navigate([`/pharmacist/reservation/${this.pci.id}/implementation`]);
  }

  specifyPciTemplate() {
    this.router.navigate([`/pharmacist/reservation/${this.pci.id}/pci-templates`]);
  }

  savePciQa() {
    const message = `服薬指導QAを保存します。よろしいですか？`;
    if (confirm(message)) {
      if (!this.pci) {
        throw new Error('pciが取得できませんでした。');
      }
      this.pciService.updatePciAnswer(this.pci, this.qaAnswer);
    }
  }

  async complete() {
    const message = `服薬指導を終了すると、服薬フォローの入力等ができなくなります。よろしいですか？`;
    if (confirm(message)) {
      const config: AxiosRequestConfig = {};
      config.headers = {};
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.complete(this.pci.id, config);
      this.fetchPci();
    }
  }

  async forceEndMeeting() {
    const message = `このボタンを押すと、現在行われているビデオ電話がある場合は、強制的に終了されます。よろしいですか？`;
    if (confirm(message)) {
      const config: AxiosRequestConfig = {};
      config.headers = {};
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.forceEndMeeting(this.pci.id, config);
      this.fetchPci();
    }
  }

  get isStartMeeting(): boolean {
    return (
      (this.pci?.status === 'confirmed' || this.pci?.status === 'meeting_performed') &&
      !!this.pci?.pci_questions_template_id &&
      !this.pci.is_meeting_started
    );
  }

  get isEndMeeting(): boolean {
    return this.pci?.status === 'meeting_completed';
  }
}
