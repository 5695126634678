<loading-spinner [loading]="loading"></loading-spinner>
<table *ngIf="!loading && notices.length" mat-table [dataSource]="notices">
  <ng-container matColumnDef="textTime">
    <th mat-header-cell *matHeaderCellDef>受付日時</th>
    <td mat-cell *matCellDef="let element">{{ element.date ? (element.date | date: 'yyyy/MM/dd  HH:mm:ss') : '' }}</td>
  </ng-container>

  <ng-container matColumnDef="patientName">
    <th mat-header-cell *matHeaderCellDef>患者名</th>
    <td mat-cell *matCellDef="let element">{{ element.patientName }}</td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>件名</th>
    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row class="clickable-row" *matRowDef="let row; columns: displayedColumns" [routerLink]="row.routerLink"></tr>
</table>

<mat-card *ngIf="!loading && !notices.length" class="no-news-card" fxLayout="column" fxLayoutAlign="center">
  新着情報はありません
</mat-card>
