import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { IPatientInfo } from 'src/models';
import { IPharmacyPatientInfo } from 'src/models/patient-info';
import { IPCF } from 'src/models/pcf';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { PcfService } from 'src/services/api/pcf.service';

@Component({
  selector: 'app-new-follow',
  templateUrl: './new-follow.component.html',
  styleUrls: ['./new-follow.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class NewFollowComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private pcfTemplateService: PCFTemplateService,
    private pcfService: PcfService,
    private patientInfoService: PatientInfoService,
    private router: Router,
    private store: Store,
  ) {}

  private get _filteredPatients(): IPatientInfo[] {
    return this.patientInfos.filter(
      i =>
        !this.patientName.value ||
        this.patientNameWithSpace(i).includes(this.patientName.value) ||
        this.patientNameWithoutSpace(i).includes(this.patientName.value) ||
        this.patientNameKanaWithSpace(i).includes(this.patientName.value) ||
        this.patientNameKanaWithoutSpace(i).includes(this.patientName.value),
    );
  }

  get selectableHour(): number[] {
    return Array(24)
      .fill(0)
      .map((_, i) => i);
  }

  get selectableMin(): number[] {
    return Array(60 / this.timeUnitMin)
      .fill(0)
      .map((_, i) => i * this.timeUnitMin);
  }
  questions: IQAT[] = [];
  loading = true;
  patientInfos: IPatientInfo[] = [];
  filteredPatients: Observable<IPatientInfo[]> = of([]);
  selectedPatient?: IPatientInfo;
  selectedDate: any;
  patientName = new FormControl();
  selectedHour = 12;
  selectedMin = 0;
  noPatientAlert = false;
  noDateAlert = false;
  pharmacistId = '';
  pharmacyId = '';
  templateId = '';
  readonly timeUnitMin: 15 | 30 = 15;
  @ViewChild('divPatient') divPatient!: HTMLDivElement;
  trackByIndex = (index: number, item: any) => index;

  ngOnInit(): void {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacist),
        filter(isNotNull),
        take(1),
      )
      .subscribe(pharmacist => {
        this.pharmacistId = pharmacist?.id;
        this.pharmacyId = pharmacist.pharmacy?.id ?? '';
      });
    const promises: Promise<any>[] = [];
    this.filteredPatients = this.patientName.valueChanges.pipe(
      startWith(''),
      map(_ => this._filteredPatients),
    );
    promises.push(this.patientInfoService.findAll());
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.templateId) {
        this.templateId = params.templateId;
        promises.push(this.pcfTemplateService.find(params.templateId).then(result => result?.qa ?? []));
      } else if (params.followId) {
        promises.push(this.pcfService.find(params.followId).then(result => result?.pcf_questions ?? []));
      } else {
        promises.push(
          new Promise<IQAT[]>(resolve => resolve([])),
        );
      }
    });
    Promise.all(promises)
      .then(result => {
        const patientInfos: IPharmacyPatientInfo[] = result[0];
        this.patientInfos = patientInfos.map(i => i.patient_info);
        this.questions = result[1];
      })
      .then(_ => (this.loading = false));
  }

  dropQuestion(event: CdkDragDrop<IQAT[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
  }

  dropChoice(index: number, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questions[index].answers, event.previousIndex, event.currentIndex);
  }

  addQuestion() {
    this.questions.push({ question: '', type: QuestionType.radio, required: false, answers: [''] });
  }

  async save() {
    let alertMessage = '';
    if (!this.selectedPatient) {
      this.noPatientAlert = true;
      alertMessage += '患者が設定されていません。\n';
    }
    if (this.selectedDate === null || this.selectedDate === undefined) {
      this.noDateAlert = true;
      alertMessage += '送信日が設定されていません。\n';
    }
    if (alertMessage) {
      setTimeout(_ => alert(alertMessage), 50);
      return;
    }
    if (confirm('この内容で服薬フォローの送信を予約しますか？')) {
      const time = this.selectedDate._d?.setHours(this.selectedHour, this.selectedMin);
      const target: IPCF = {
        id: '',
        pharmacy_id: this.pharmacyId,
        pharmacist_id: this.pharmacistId,
        patient_account_id: this.selectedPatient?.patient_account_id,
        patient_info_id: this.selectedPatient?.id,
        template_id: this.templateId,
        pcf_questions: this.questions,
        send_at: time,
      };
      await this.pcfService.create(target);
      this.router.navigate(['pharmacist/follow']);
    }
  }

  patientNameWithSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name + ' ' + patientInfo.given_name;
  }
  patientNameKanaWithSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name_kana + ' ' + patientInfo.given_name_kana;
  }
  patientNameWithoutSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name + patientInfo.given_name;
  }
  patientNameKanaWithoutSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name_kana + patientInfo.given_name_kana;
  }
}
