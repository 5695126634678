<div fxLayout="column">
  <div class="add-button-area" fxLayout="row-reverse">
    <button mat-raised-button color="primary" routerLink="/pharmacy/pcf-templates/new-template">
      新規テンプレート作成
    </button>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <mat-paginator
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
      ></mat-paginator>
      <table class="table" mat-table [dataSource]="pcfTemplates">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>テンプレート名</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          routerLink="/pharmacy/pcf-templates/{{ row.id }}"
          class="clickable-row"
        ></tr>
      </table>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
