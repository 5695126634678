<mat-dialog-content>
  <div>{{'患者氏名 : '+follow.patient_name}}</div>
  <div>{{'状態 : '+follow.status}}</div>
  <div *ngIf="follow.status!=='下書き'">{{'送信日時 : '+(follow.send_at | date: 'yyyy/MM/dd HH:mm')}}</div>
  <div *ngIf="['回答済み','確認済み','終了'].includes(follow.status)">
    {{'回答日時 : '+(follow.answered_at|date:'yyyy/MM/dd HH:mm')}}
  </div>
  <div class="qa-container">
    <div class="qa-content" *ngFor="let question of follow.pcf_questions index as i">
      <div class="question">
        <mat-chip-list fxLayout="row">
          <mat-chip *ngIf="question.type === 'radio'" [selectable]="false">1つ選択</mat-chip>
          <mat-chip *ngIf="question.type === 'checkbox'">複数選択</mat-chip>
          <mat-chip *ngIf="question.type === 'textbox'">自由記述</mat-chip>
        </mat-chip-list>
        <mat-divider class="cq-divider"></mat-divider>
        <div>{{question.question}}</div>
        <div *ngFor="let choice of question.answers">・{{choice}}</div>
      </div>
      <mat-divider class="qa-divider"></mat-divider>
      <div class="answer" *ngIf="['回答済み','確認済み','終了'].includes(follow.status)">
        >> {{follow.pcf_answers?follow.pcf_answers[i]?follow.pcf_answers[i]:'(無回答)':''}}
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button mat-button mat-dialog-close>閉じる</button>
  <loading-button
    *ngIf="follow.status==='確認済み'"
    [loading]="loading"
    [name]="'終了'"
    color="accent"
    click="finalize()"
  ></loading-button>
  <loading-button
    *ngIf="follow.status==='回答済み'"
    [loading]="loading"
    [name]="'確認'"
    color="primary"
    (click)="confirm()"
  ></loading-button>
  <loading-button
    *ngIf="follow.status==='下書き'"
    [loading]="loading"
    [name]="'編集'"
    color="primary"
    click="edit()"
  ></loading-button>
  <loading-button
    *ngIf="follow.status==='送信予約済み'"
    [loading]="loading"
    [name]="'削除'"
    color="warn"
    (click)="delete()"
  ></loading-button>
  <loading-button
    *ngIf="follow.status==='送信済み'"
    [loading]="loading"
    [name]="'取り消し'"
    color="warn"
    (click)="cancel()"
  ></loading-button>
</mat-dialog-actions>
