import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { Session } from 'src/models/session';
import { CognitoService } from 'src/services/cognito.service';
import { loginSessions, loadSessions } from './app-store/actions/session.actions';
import { getLoading } from './app-store/reducers';
import { AuthGuard } from './guard/auth.guard';
import * as fromSession from './app-store/reducers';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, of } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private breakPointObserver: BreakpointObserver,
  ) {}

  loading$ = this.store.select<boolean>(getLoading as any);
  isSmallScreen$: Observable<boolean> = of(false);
  private titleSuffix = environment.title;
  private _title = '';
  type?: 'pharmacist' | 'pharmacy';

  get title(): string {
    return this._title ? this.titleSuffix : `${this._title} | ${this.titleSuffix}`;
  }

  async ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.firstChild?.snapshot.data.title ?? appTitle),
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
        this._title = title;
      });
    this.isSmallScreen$ = this.breakPointObserver.observe(['(max-width: 1200px)']).pipe(map(state => state.matches));
    this.store.select<Session>(fromSession.getSession as any).subscribe(async s => (this.type = s.type));
  }

  closeSidenav(sidenav: MatSidenav): void {
    this.isSmallScreen$.subscribe(x => {
      if (x) {
        sidenav.close();
      }
    });
  }
}
