<div class="root">
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row-reverse">
      <loading-button
        [name]="'保存'"
        [disabled]="loading"
        [color]="'primary'"
        [loading]="loading"
        (click)="saveTemplate()"
      ></loading-button>
    </div>
    <app-questionnaire-editor [(questions)]="questions" [disabled]="loading"></app-questionnaire-editor>
  </div>
</div>
