<div>
  <form>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <mat-form-field>
        <mat-label for="name">名前で検索</mat-label>
        <input type="text" matInput name="name" [(ngModel)]="name" />
      </mat-form-field>
      <div class="toggle-buttons">
        <mat-button-toggle-group
          [value]="viewType"
          (change)="toggleViewType(group.value)"
          #group="matButtonToggleGroup"
        >
          <mat-button-toggle value="calendar">カレンダー</mat-button-toggle>
          <mat-button-toggle value="list">リスト</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>

  <list *ngIf="viewType === 'list'" [nameFilter]="name"></list>
  <calendar *ngIf="viewType === 'calendar'" [nameFilter]="name"></calendar>
</div>
