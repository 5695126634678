import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultMeetingSession } from 'amazon-chime-sdk-js';
import { AxiosRequestConfig } from 'axios';
import { ReservationForList } from 'src/models/pci';
import { PciService } from 'src/services/api/pci.service';
import { CognitoService } from 'src/services/cognito.service';

@Component({
  selector: 'meeting-end-dialog',
  templateUrl: './meeting-end-dialog.component.html',
  styleUrls: ['./meeting-end-dialog.component.scss'],
})
export class MeetingEndDialogComponent implements OnInit {
  reservation: ReservationForList;
  private meetingSession: DefaultMeetingSession;
  type: 'pharmacist' | 'patient';

  constructor(
    public dialogRef: MatDialogRef<MeetingEndDialogComponent>,
    private cognito: CognitoService,
    private pciService: PciService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      pci: ReservationForList;
      meetingSession: DefaultMeetingSession;
      type: 'pharmacist' | 'patient';
    },
  ) {
    this.reservation = data.pci;
    this.meetingSession = data.meetingSession;
    this.type = data.type;
  }

  ngOnInit(): void {}

  public async endMeeting(type?: 'completed') {
    const config = await this.config();
    if (this.type === 'pharmacist') {
      await this.meetingSession.audioVideo.stop();
      await this.pciService.endMeeting(this.reservation.id, config).catch(async r => {
        if ((r.response.status === 200 || r.response.status === 404) && type) {
          await this.pciService.completeMeeting(this.reservation.id, config);
        }
      });
    }
    if (type) {
      await this.pciService.completeMeeting(this.reservation.id, config);
    }
    this.dialogRef.close({ end_meeting: true });
  }

  public close() {
    this.dialogRef.close();
  }

  private async config() {
    const config: AxiosRequestConfig = {};
    if (!config.headers) {
      config.headers = {};
    }
    const token = await this.cognito.getAccessToken();
    config.headers.Authorization = token.getJwtToken();
    config.headers['Content-Type'] = 'application/json';
    return config;
  }
}
