import { Component, OnInit } from '@angular/core';
import { LinkCardContent } from 'src/app/parts/card/card.component';

@Component({
  selector: 'pharmacy-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  linkContents: LinkCardContent[] = [
    {
      href: '/pharmacy/pharmacists',
      title: '薬剤師一覧',
      text: `薬局管理者が自薬局の薬剤師のアカウントを作成し、薬剤師を登録する`,
    },
    {
      href: '/pharmacy/reservation-slots',
      title: '予約枠一覧',
      text: `薬局管理者が自薬局のオンライン服薬指導予約枠を登録する`,
    },
    {
      href: '/pharmacy/pcf-templates',
      title: '服薬フォローテンプレート一覧',
      text: `薬局管理者が自薬局のオンライン服薬指導の際に用いる服薬フォローテンプレートを登録する`,
    },
    // {
    //   href: '/pharmacy/cbf-templates',
    //   title: 'チャットボット服薬フォローテンプレート一覧',
    //   text: `薬局管理者が自薬局の薬剤師のアカウントを作成し、薬剤師を登録する`,
    // },
    {
      href: '/pharmacy/medical-questionnaire-template',
      title: '問診票テンプレート一覧',
      text: `薬局管理者が自薬局のオンライン服薬指導の際に用いる問診票テンプレートを登録する`,
    },
    {
      href: '/pharmacy/pci-templates',
      title: '服薬指導テンプレート一覧',
      text: `薬局管理者が自薬局のオンライン服薬指導の際に用いる服薬指導テンプレートを登録する`,
    },
    {
      href: '/pharmacy/profile',
      title: '薬局情報編集',
      text: `薬局管理者が自薬局の情報を編集する`,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
