<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading" class="root">
  <div fxLayout="column" fxLayoutGap="15px">
    <div *ngIf="prescription?.status === 'sent'" fxLayout="row-reverse" fxLayoutGap="10px">
      <button mat-raised-button (click)="confirm()" color="primary">受領</button>
      <button mat-raised-button (click)="openRequestResendDialog()" color="accent">再送要求</button>
      <button mat-raised-button (click)="openRejectDialog()" color="warn">拒否</button>
    </div>
    <div *ngIf="prescription?.status === 'confirmed'" fxLayout="row-reverse" fxLayoutGap="10px">
      <button mat-raised-button (click)="dispensed()" color="primary">調剤完了</button>
    </div>
    <div *ngIf="prescription?.status === 'dispensed'" fxLayout="row-reverse" fxLayoutGap="10px">
      <button mat-raised-button (click)="complete()" color="primary">処方終了</button>
    </div>
    <div class="prescription-progress" fxLayout="column" fxLayoutGap="30px" fxFlexOffset="30px">
      <div fxFlexAlign="stretch" fxLayout="column" fxLayoutGap="0px">
        <mat-progress-bar
          class="progress-bar"
          [value]="onNormalFlow ? (prescription?.status | prescription_progress) : 50"
          [color]="onNormalFlow ? 'primary' : 'accent'"
        ></mat-progress-bar>
        <mat-chip-list class="chip-list">
          <mat-chip
            [color]="'primary'"
            [selected]="prescription?.status === 'sent'"
            [style.right]="'calc(' + (100 - ('sent' | prescription_progress)) + '% - 5px)'"
            [disabled]="
              prescription?.status === 'confirmed' ||
              prescription?.status === 'dispensed' ||
              prescription?.status === 'completed' ||
              prescription?.status === 'req_resend' ||
              prescription?.status === 'rejected'
            "
          >
            受信
          </mat-chip>
          <mat-chip
            *ngIf="onNormalFlow"
            [color]="'primary'"
            [selected]="prescription?.status === 'confirmed'"
            [style.right]="'calc(' + (100 - ('confirmed' | prescription_progress)) + '% - 5px)'"
            [disabled]="prescription?.status === 'dispensed' || prescription?.status === 'completed'"
          >
            {{ 'confirmed' | prescription_status }}
          </mat-chip>
          <mat-chip
            *ngIf="onNormalFlow"
            [color]="'primary'"
            [selected]="prescription?.status === 'dispensed'"
            [style.right]="'calc(' + (100 - ('dispensed' | prescription_progress)) + '% - 5px)'"
            [disabled]="prescription?.status === 'completed'"
          >
            {{ 'dispensed' | prescription_status }}
          </mat-chip>
          <mat-chip
            *ngIf="onNormalFlow"
            [color]="'primary'"
            [selected]="prescription?.status === 'completed'"
            [style.right]="'calc(' + (100 - ('completed' | prescription_progress)) + '% - 5px)'"
          >
            {{ 'completed' | prescription_status }}
          </mat-chip>
          <mat-chip
            class="accent state-chip-resend"
            *ngIf="prescription?.status === 'req_resend'"
            [color]="'accent'"
            [selected]="prescription?.status === 'req_resend'"
          >
            {{ 'req_resend' | prescription_status }}
          </mat-chip>
          <mat-chip
            class="accent state-chip-reject"
            *ngIf="prescription?.status === 'rejected'"
            [color]="'accent'"
            [selected]="prescription?.status === 'rejected'"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            {{ 'rejected' | prescription_status }}
          </mat-chip>
        </mat-chip-list>
        <div class="timestamps">
          <div
            class="timestamp"
            [style.right]="'calc(' + (100 - ('sent' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.created_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.created_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.confirmed_at !== undefined &&
              (prescription?.status === 'confirmed' ||
                prescription?.status === 'dispensed' ||
                prescription?.status === 'completed')
            "
            class="timestamp"
            [style.right]="'calc(' + (100 - ('confirmed' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.confirmed_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.confirmed_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.dispensed_at !== undefined &&
              (prescription?.status === 'dispensed' || prescription?.status === 'completed')
            "
            class="timestamp"
            [style.right]="'calc(' + (100 - ('dispensed' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.dispensed_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.dispensed_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="prescription?.completed_at !== undefined && prescription?.status === 'completed'"
            class="timestamp"
            [style.right]="'calc(' + (100 - ('completed' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.completed_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.completed_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="prescription?.req_resend_at !== undefined && prescription?.status === 'req_resend'"
            class="timestamp timestamp-resend"
            fxLayout="column"
          >
            <div>{{ prescription?.req_resend_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.req_resend_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="prescription?.rejected_at !== undefined && prescription?.status === 'rejected'"
            class="timestamp timestamp-reject"
            fxLayout="column"
          >
            <div>{{ prescription?.rejected_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.rejected_at | date: 'HH:mm:ss' }}</div>
          </div>
        </div>
      </div>
    </div>
    <mat-accordion fxLayout="column" fxLayoutGap="15px" fxFlexOffset="85px" multi>
      <mat-expansion-panel class="patient-info" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>患者情報</mat-panel-title>
          <mat-panel-description fxLayout="row-reverse">
            <mat-icon fxFlexAlign="center">account_circle</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="info-panel-content" fxLayout="column">
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">患者氏名</div>
            <div fxFlex="60" class="value">
              <a class="patient-info-link-global" [routerLink]="'/pharmacist/patients/' + patientInfoId">
                {{ patientName }}
              </a>
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">患者氏名 (かな)</div>
            <div fxFlex="60" class="value">
              {{ patientNameKana }}
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">性別</div>
            <div fxFlex="60" class="value">{{ prescription?.patient_info?.sex | patient_sex }}</div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">郵便番号</div>
            <div fxFlex="60" class="value">
              {{ prescription?.patient_info?.zip ? prescription?.patient_info?.zip : '' }}
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">都道府県</div>
            <div fxFlex="60" class="value">
              {{ prescription?.patient_info?.prefecture ? prescription?.patient_info?.prefecture : '' }}
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">住所1</div>
            <div fxFlex="60" class="value">
              {{ prescription?.patient_info?.address1 ? prescription?.patient_info?.address1 : '' }}
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">住所2</div>
            <div fxFlex="60" class="value">
              {{ prescription?.patient_info?.address2 ? prescription?.patient_info?.address2 : '' }}
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">住所3</div>
            <div fxFlex="60" class="value">
              {{ prescription?.patient_info?.address3 ? prescription?.patient_info?.address3 : '' }}
            </div>
          </div>
          <div class="info-row" fxLayout="row">
            <div fxFlex="40" class="attribute">電話番号</div>
            <div fxFlex="60" class="value">
              {{ prescription?.patient_info?.tel ? prescription?.patient_info?.tel : '' }}
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="medical-questionnaire">
        <mat-expansion-panel-header>
          <mat-panel-title>問診票</mat-panel-title>
          <mat-panel-description fxLayout="row-reverse">
            <mat-icon fxFlexAlign="center">quiz</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="questionnaire-panel-content" fxLayout="column">
          <div
            class="questionnaire-row"
            *ngFor="let question of prescription?.mq_questions; index as i"
            fxLayout="row"
            fxLayout.lt-sm="column"
          >
            <div fxFlex="50" class="question" fxLayout="column">
              <div>{{ question.question }}</div>
              <div *ngFor="let choice of question.answers">・{{ choice }}</div>
            </div>
            <div fxFlex="50" class="answer">
              {{ getAnswer(i) }}
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div fxLayout="row-reverse" fxFlexOffset="20px">
      <button mat-stroked-button class="print-button" (click)="print()">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-icon fxFlexAlign="center">print</mat-icon>
          <div fxFlexAlign="center">処方箋画像を印刷</div>
        </div>
      </button>
    </div>
    <carousel
      #carousel
      fxFlexOffset="20px"
      class="carousel hidden-on-print"
      [loop]="false"
      [dots]="true"
      [counter]="true"
      [cellsToShow]="1"
      [height]="900"
      [objectFit]="'contain'"
      [images]="imagesForCarousel"
    ></carousel>
  </div>
</div>

<div *ngFor="let image of images" class="on-print print-image-wrap">
  <img class="on-print print-image" [src]="image" />
</div>
