import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  @Output() sidenavCloseForce = new EventEmitter();
  type?: 'pharmacy' | 'pharmacist';
  items: {
    type: 'pharmacy' | 'pharmacist';
    href: string;
    title: [string, string?];
    icon?: string;
    current: boolean;
  }[] = [
    {
      type: 'pharmacy',
      href: '/pharmacy/pharmacists',
      title: ['薬剤師一覧'],
      icon: 'people',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/reservation-slots',
      title: ['予約枠一覧'],
      icon: 'event_note',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/pcf-templates',
      title: ['服薬フォロー', 'テンプレート'],
      icon: 'article',
      current: false,
    },
    // {
    //   type: 'pharmacy',
    //   href: '/pharmacy/cbf-templates',
    //   title: ['チャットボット', '服薬フォローテンプレート'],
    //   icon: 'android',
    // },
    {
      type: 'pharmacy',
      href: '/pharmacy/medical-questionnaire-template',
      title: ['問診票テンプレート'],
      icon: 'quiz',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/pci-templates',
      title: ['服薬指導テンプレート'],
      icon: 'question_answer',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/profile',
      title: ['薬局情報編集'],
      icon: 'manage_accounts',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/reservation',
      title: ['服薬指導予約一覧'],
      icon: 'event_note',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/prescriptions',
      title: ['受信処方箋画像一覧'],
      icon: 'receipt',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/patients',
      title: ['患者情報一覧'],
      icon: 'people',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/follow',
      title: ['服薬フォロー一覧'],
      icon: 'question_answer',
      current: false,
    },
  ];
  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    getLoginSession(this.store).subscribe(s => (this.type = s.type));
    this.router.events.pipe(filter(f => f instanceof NavigationEnd)).subscribe((s: any) => {
      for (const item of this.items) {
        const arr = item.href.split('/').filter(e => e);
        if (arr.every(e => s.url.includes(e))) {
          item.current = true;
        } else {
          item.current = false;
        }
      }
    });
  }

  closeSidenav() {
    this.sidenavClose.emit();
  }
  closeSidenavForce() {
    this.sidenavCloseForce.emit();
  }

  get filteredItems() {
    return this.items.filter(i => i.type === this.type);
  }
}
